// components/ResponsePathsTable.js

import React, { useState } from 'react';
import { Table, Input, Checkbox, Button, Modal as AntdModal } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';


const ResponsePathsTable = ({ responsePaths, setResponsePaths }) => {
  const columns = [
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      render: (text, record, index) => (
        <Checkbox
          checked={record.active}
          onChange={(e) => handleCheckboxChange(e.target.checked, index)}
        />
      ),
    },
    {
      title: 'Response Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record, index) => (
        <Input
          value={text}
          onChange={(e) => handleNameChange(e.target.value, index)}
          placeholder="e.g., status"
        />
      ),
    },
    {
      title: 'Response Path (JSON Path)',
      dataIndex: 'path',
      key: 'path',
      render: (text, record, index) => (
        <Input
          value={text}
          onChange={(e) => handlePathChange(e.target.value, index)}
          placeholder="e.g., $.status"
        />
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record, index) => (
        <Button
          type="danger"
          icon={<DeleteOutlined />}
          onClick={() => handleDelete(index)}
        />
      ),
    },
  ];

  const handleCheckboxChange = (checked, index) => {
    const newPaths = [...responsePaths];
    newPaths[index].active = checked;
    setResponsePaths(newPaths);
  };

  const handleNameChange = (value, index) => {
    const newPaths = [...responsePaths];
    newPaths[index].name = value;
    setResponsePaths(newPaths);
  };

  const handlePathChange = (value, index) => {
    const newPaths = [...responsePaths];
    newPaths[index].path = value;
    setResponsePaths(newPaths);
  };

  const handleDelete = (index) => {
    const newPaths = [...responsePaths];
    newPaths.splice(index, 1);
    setResponsePaths(newPaths);
  };

  const handleAdd = () => {
    setResponsePaths([...responsePaths, { name: '', path: '', active: false }]);
  };

  const [isGenerateModalVisible, setIsGenerateModalVisible] = useState(false);
  const [sampleResponse, setSampleResponse] = useState('');

  const handleGenerateFromResponse = () => {
    setIsGenerateModalVisible(true);
  };

  const handleGenerateOk = () => {
    try {
      const json = JSON.parse(sampleResponse);
      const paths = extractJsonPaths(json);
      const existingPaths = responsePaths.map((item) => item.path);
      const newUniquePaths = paths.filter(
        (item) => !existingPaths.includes(item.path)
      );
      const newPaths = newUniquePaths.map((path) => ({
        name: path.name,
        path: path.path,
        active: false,
      }));
      setResponsePaths([...responsePaths, ...newPaths]);
      setIsGenerateModalVisible(false);
      setSampleResponse('');
    } catch (error) {
      alert('Invalid JSON input');
    }
  };

  const handleGenerateCancel = () => {
    setIsGenerateModalVisible(false);
    setSampleResponse('');
  };

  // Function to extract JSON paths
  const extractJsonPaths = (json, parentPath = '', parentName = '') => {
    let paths = [];
    if (Array.isArray(json)) {
      const currentPath = `${parentPath}[*]`;
      const currentName = parentName ? `${parentName}[*]` : '[*]';
      paths.push({ name: currentName, path: currentPath });
      json.forEach((item) => {
        paths = paths.concat(extractJsonPaths(item, currentPath, currentName));
      });
    } else if (typeof json === 'object' && json !== null) {
      Object.keys(json).forEach((key) => {
        const currentPath = parentPath ? `${parentPath}.${key}` : key;
        const currentName = parentName ? `${parentName}.${key}` : key;
        paths.push({ name: currentName, path: currentPath });
        paths = paths.concat(extractJsonPaths(json[key], currentPath, currentName));
      });
    }
    return paths;
  };

  return (
    <div>
      <Button
        type="primary"
        onClick={handleAdd}
        style={{ marginBottom: '10px', marginRight: '10px' }}
      >
        Add Response Path
      </Button>
      <Button onClick={handleGenerateFromResponse} style={{ marginBottom: '10px' }}>
        Generate from Response
      </Button>
      <Table
        dataSource={responsePaths}
        columns={columns}
        pagination={false}
        rowKey={(record, index) => index}
      />

      {/* Modal for generating paths */}
      <AntdModal
        title="Generate Paths from Response"
        visible={isGenerateModalVisible}
        onOk={handleGenerateOk}
        onCancel={handleGenerateCancel}
        width={600}
      >
        <Input.TextArea
          value={sampleResponse}
          onChange={(e) => setSampleResponse(e.target.value)}
          placeholder="Paste JSON response here"
          rows={10}
        />
      </AntdModal>
    </div>
  );
};

export default ResponsePathsTable;