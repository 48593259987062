// src/components/nodes/CombineNode.js

import React from 'react';
import { Handle } from 'react-flow-renderer';
import { BsGraphUp } from 'react-icons/bs'; // Example icon
import './NodeStyling.css'; // Assume you have some styling

const CombineNode = ({ data }) => {
  return (
    <div className="node-border">
<div className='topBar'> <BsGraphUp className="nodeIcon" />
        <span style={{ fontWeight: 'bold' }}>Combine Node</span>
        <span
          className="node-delete"
          onClick={data.deleteNode}
          title="Delete Node"
        >
          x
        </span>
      </div>

      {/* Display Combined Data */}
      {data.combinedData && (
        <pre
          style={{
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-all',
            textAlign: 'left',
            maxHeight: '100px',
            overflowY: 'auto',
            backgroundColor: '#f5f5f5',
            padding: '5px',
            borderRadius: '4px',
            marginBottom: '10px',
          }}
        >
          {data.combinedData}
        </pre>
      )}

      {/* Single Target Handle */}
      <Handle
        type="target"
        position="left"
        id="input"
        style={{ top: '50%', transform: 'translateY(-50%)' }}
        isConnectable={true}
      />

      {/* Single Source Handle */}
      <Handle
        type="source"
        position="right"
        id="output"
        style={{ top: '50%', transform: 'translateY(-50%)' }}
        isConnectable={true}
      />
    </div>
  );
};

CombineNode.defaultData = {
  combinedData: '',
};

export default CombineNode;