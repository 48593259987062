// ShapeNode.jsx
import React, { useState } from 'react';
import { BsPencilSquare } from 'react-icons/bs';
import * as BsIcons from 'react-icons/bs';
import { Modal, Button } from 'antd';
import IconSelector from '../IconSelector';
import './ShapeNode.css';
import { useReactFlow } from 'react-flow-renderer';

const ShapeNode = ({ data, id }) => {
  const { setNodes } = useReactFlow();
  // eslint-disable-next-line
  const [selectedIcon, setSelectedIcon] = useState('BsFillArrowRightCircleFill'); // Default icon
  const [showIconSelector, setShowIconSelector] = useState(false); // Toggle for icon selector
  const [showControls, setShowControls] = useState(false); // Toggle for X and shape selector

  // Handle icon change
  const handleIconChange = (iconName) => {
    setShowIconSelector(false);

    // Update node's data using setNodes
    setNodes((nds) =>
      nds.map((node) =>
        node.id === id
          ? {
              ...node,
              data: {
                ...node.data,
                selectedIcon: iconName,
              },
            }
          : node
      )
    );
  };


  // Dynamically render the selected icon using the icon name
  const renderSelectedIcon = () => {
    const IconComponent = BsIcons[data.selectedIcon || 'BsFillArrowRightCircleFill'];
    return <IconComponent size={50} />;
  };

  // Function to toggle controls visibility when the icon is clicked
  const toggleControls = (e) => {
    e.stopPropagation(); // Prevent the click from bubbling up to the document
    setShowControls((prev) => !prev);
    // Optionally hide the icon selector when toggling controls
    if (showIconSelector && showControls) {
      setShowIconSelector(false);
    }
  };

  return (
    <div
      className="shape-node-container"
      onClick={toggleControls} // Toggle controls when the shape is clicked
    >
      {/* Display the selected icon */}
      <div className="selected-icon">
        {renderSelectedIcon()}
      </div>

      {/* Delete Button and Shape Selector - Only show when controls are visible */}
      {showControls && (
        <>
          {/* Delete Button */}
          <Button
            type="text"
          
            icon={<span >x</span>}
            className="delete-button"
            onClick={(e) => {
              e.stopPropagation();
              data.deleteNode();
              setShowControls(false);
              setShowIconSelector(false);
            }}
          />

          {/* Shape Selector Icon */}
          <Button
            type="text"
            icon={<BsPencilSquare size={20} />}
            className="shape-selector-button"
            onClick={(e) => {
              e.stopPropagation();
              setShowIconSelector(true);
            }}
          />
        </>
      )}

      {/* Icon Selector Modal */}
      <Modal
        title="Select an Icon"
        visible={showIconSelector}
        onCancel={() => setShowIconSelector(false)}
        footer={null}
        width={500}
        centered
        destroyOnClose
        bodyStyle={{ padding: 0 }}
      >
        <IconSelector onSelect={handleIconChange} onClose={() => setShowIconSelector(false)} />
      </Modal>
    </div>
  );
};

export default ShapeNode;