// src/components/HeadersTable.js

import React from 'react';
import { Table, Input, Button } from 'antd';

const HeadersTable = ({ headers, setHeaders }) => {
  const columns = [
    {
      title: 'Header Name',
      dataIndex: 'name',
      render: (text, record, index) => (
        <Input
          value={text}
          onChange={(e) => handleHeaderChange(index, 'name', e.target.value)}
        />
      ),
    },
    {
      title: 'Header Value',
      dataIndex: 'value',
      render: (text, record, index) => (
        <Input
          value={text}
          onChange={(e) => handleHeaderChange(index, 'value', e.target.value)}
        />
      ),
    },
    {
      title: 'Actions',
      render: (_, __, index) => (
        <Button onClick={() => handleDeleteHeader(index)}>Delete</Button>
      ),
    },
  ];

  const handleHeaderChange = (index, key, value) => {
    const newHeaders = [...headers];
    newHeaders[index][key] = value;
    setHeaders(newHeaders);
  };

  const handleAddHeader = () => {
    setHeaders([...headers, { name: '', value: '' }]);
  };

  const handleDeleteHeader = (index) => {
    const newHeaders = [...headers];
    newHeaders.splice(index, 1);
    setHeaders(newHeaders);
  };

  return (
    <div>
      <Table
        dataSource={headers}
        columns={columns}
        pagination={false}
        rowKey={(record, index) => index}
      />
      <Button onClick={handleAddHeader} style={{ marginTop: '10px' }}>
        Add Header
      </Button>
    </div>
  );
};

export default HeadersTable;
