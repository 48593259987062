import React, { useEffect, useRef } from 'react';

const RandomizedFireworksGridBackground = () => {
  const canvasRef = useRef(null);
  const gridSize = 100; // Size of each grid cell
  const dotSize = 2; // Size of background dots
  const minLineDrawDuration = 4000; // Minimum duration to draw a line (in milliseconds)
  const maxLineDrawDuration = 8000; // Maximum duration to draw a line (in milliseconds)
  const minLineLifespan = 10000; // Minimum lifespan of a line (in milliseconds)
  const maxLineLifespan = 15000; // Maximum lifespan of a line (in milliseconds)
  const maxActiveShapes = 15; // Maximum number of active shapes at any time

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const width = canvas.width = window.innerWidth;
    const height = canvas.height = window.innerHeight;

    let activeShapes = [];

    const drawBackgroundDots = () => {
      ctx.fillStyle = 'rgba(255, 255, 255, 0.2)';
      for (let x = 0; x <= width; x += gridSize) {
        for (let y = 0; y <= height; y += gridSize) {
          ctx.beginPath();
          ctx.arc(x, y, dotSize, 0, Math.PI * 2);
          ctx.fill();
        }
      }
    };

    const getRandomGridPoint = () => ({
      x: Math.floor(Math.random() * (width / gridSize)) * gridSize,
      y: Math.floor(Math.random() * (height / gridSize)) * gridSize
    });

    const createNewShape = () => {
      const start = getRandomGridPoint();
      const end = {
        x: start.x + gridSize * (Math.floor(Math.random() * 3) + 1),
        y: start.y + gridSize * (Math.floor(Math.random() * 3) + 1)
      };
      const startTime = Date.now();
      const drawDuration = Math.random() * (maxLineDrawDuration - minLineDrawDuration) + minLineDrawDuration;
      const lifespan = Math.random() * (maxLineLifespan - minLineLifespan) + minLineLifespan;

      return { start, end, startTime, drawDuration, lifespan };
    };

    const drawShape = (shape, progress) => {
      const { start, end } = shape;
      ctx.strokeStyle = '#535353';
      ctx.lineWidth = 1;
      
      const drawLine = (x1, y1, x2, y2, lineProgress) => {
        ctx.beginPath();
        ctx.moveTo(x1, y1);
        ctx.lineTo(x1 + (x2 - x1) * lineProgress, y1 + (y2 - y1) * lineProgress);
        ctx.stroke();
      };

      // Draw top line
      drawLine(start.x, start.y, end.x, start.y, progress);

      // Draw right line
      drawLine(end.x, start.y, end.x, end.y, progress);

      // Draw bottom line
      drawLine(end.x, end.y, start.x, end.y, progress);

      // Draw left line
      drawLine(start.x, end.y, start.x, start.y, progress);
    };

    const animate = () => {
      ctx.clearRect(0, 0, width, height);
      drawBackgroundDots();

      const currentTime = Date.now();

      // Remove expired shapes
      activeShapes = activeShapes.filter(shape => 
        currentTime - shape.startTime < shape.lifespan
      );

      // Add new shapes if needed
      while (activeShapes.length < maxActiveShapes) {
        activeShapes.push(createNewShape());
      }

      // Draw active shapes
      activeShapes.forEach(shape => {
        const shapeAge = currentTime - shape.startTime;
        if (shapeAge < shape.drawDuration) {
          // Drawing phase
          const progress = shapeAge / shape.drawDuration;
          drawShape(shape, progress);
        } else {
          // Fading phase
          const fadeProgress = (shapeAge - shape.drawDuration) / (shape.lifespan - shape.drawDuration);
          ctx.globalAlpha = 1 - fadeProgress;
          drawShape(shape, 1);
          ctx.globalAlpha = 1;
        }
      });

      requestAnimationFrame(animate);
    };

    animate();

    // Handle window resize
    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return <canvas ref={canvasRef} style={{ backgroundColor: 'black', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: '-1' }} />;
};

export default RandomizedFireworksGridBackground;