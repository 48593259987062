import React, { useState, useEffect, useRef } from 'react';
import {
  Card,
  Button,
  Row,
  Col,
  message,
  Modal,
  Form,
  Input,
  Select,
  Typography,
  Space,
} from 'antd';
import axios from 'axios';
import * as Icons from 'react-icons/bs';
import IconSelector from './components/IconSelector';
import HeadersTable from './components/HeadersTable';
import DataFieldsTable from './components/DataFieldsTable';
import VariablesTable from './components/VariablesTable';
import ResponsePathsTable from './components/ResponsePathsTable';
import { v4 as uuidv4 } from 'uuid';
import {
  PlusOutlined,
  UploadOutlined,
  EditOutlined,
  DeleteOutlined,
  ImportOutlined,
} from '@ant-design/icons';
import DOMPurify from 'dompurify'; // Import DOMPurify

const { Meta } = Card;
const { Option } = Select;
const { Text, Title } = Typography;

const DEFAULT_NODE_CONFIG = {
  nodeName: "",
  apiKey: "",
  description: "",
  instructions: "",
  fetchList: [],
  headers: {},
  dataFields: {},
  variables: {},
  responsePaths: {},
  dataRaw: {},
  category: "",
  iconName: "",
};

const Marketplace = ({ boardId, userId, addCustomNode }) => {
  // eslint-disable-next-line
  const [userAccess, setUserAccess] = useState(localStorage.getItem('userAccess') || '');
  const [marketplaceNodes, setMarketplaceNodes] = useState([]);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [customApiConfig, setCustomApiConfig] = useState(DEFAULT_NODE_CONFIG);
  const [isIconSelectorVisible, setIsIconSelectorVisible] = useState(false);
  const [modalSelectedFetchId, setModalSelectedFetchId] = useState(null);
  const [newFetchEntryName, setNewFetchEntryName] = useState('');
  const customNodeFileInputRef = useRef(null);
  const [selectedNode, setSelectedNode] = useState(null); // For detailed view modal
  const [isDetailModalVisible, setIsDetailModalVisible] = useState(false);

  useEffect(() => {
    fetchMarketplaceNodes();
  }, []);

  const fetchMarketplaceNodes = async () => {
    try {
      const response = await axios.get('https://nodecodestudio.com/backend/get_marketplace_nodes.php');
  
      const nodes = response.data.nodes.map((node) => {
        // Parse restOfConfig if it's a JSON string
        if (typeof node.restOfConfig === 'string') {
          node.restOfConfig = JSON.parse(node.restOfConfig);
        }
        return node;
      });
  
      setMarketplaceNodes(nodes);
    } catch (error) {
      console.error('Error fetching marketplace nodes:', error);
      message.error('Failed to load Marketplace nodes.');
    }
  };

  const showAddModal = () => {
    setCustomApiConfig(DEFAULT_NODE_CONFIG);
    setModalSelectedFetchId(null);
    setIsAddModalVisible(true);
  };

  const handleImportToBoard = async (marketId) => {
    if (!boardId) {
      message.error('Board ID not available. Please navigate to a specific board.');
      return;
    }
  
    try {
      const node = marketplaceNodes.find(node => node.id === marketId);
      if (!node) {
        throw new Error('Marketplace node not found.');
      }
  
      // Parse restOfConfig if necessary
      let restOfConfig = node.restOfConfig || {};
      if (typeof restOfConfig === 'string') {
        restOfConfig = JSON.parse(restOfConfig);
      }
  
      // Merge restOfConfig into node
      const completeNodeConfig = {
        ...DEFAULT_NODE_CONFIG,
        ...node,
        ...restOfConfig,
        fetchList: restOfConfig.fetchList || [],
        headers: restOfConfig.headers || {},
        dataFields: restOfConfig.dataFields || {},
        variables: restOfConfig.variables || {},
        responsePaths: restOfConfig.responsePaths || {},
        dataRaw: restOfConfig.dataRaw || {},
      };
  
      setCustomApiConfig(completeNodeConfig);
  
      if (completeNodeConfig.fetchList.length > 0) {
        setModalSelectedFetchId(completeNodeConfig.fetchList[0].id);
      } else {
        setModalSelectedFetchId(null);
      }
  
      // Optionally, you can open the modal to show the imported node
      // setIsAddModalVisible(true);
  
      const payload = {
        market_id: marketId,
        board_id: boardId,
        user_id: userId,
      };
  
      await axios.post('https://nodecodestudio.com/backend/link_marketplace_node.php', payload, {
        headers: { 'Content-Type': 'application/json' },
      });
  
      message.success('Marketplace node imported successfully.');
      addCustomNode(node);
    } catch (error) {
      console.error('Error importing node:', error);
      message.error('Failed to import node. Please try again.');
    }
  };

  const handleEdit = (node) => {
    const restConfig = node.restOfConfig || {};

    const completeNodeConfig = {
      ...DEFAULT_NODE_CONFIG,
      ...node,
      ...restConfig,
      fetchList: restConfig.fetchList ? restConfig.fetchList : [],
      headers: restConfig.headers ? restConfig.headers : {},
      dataFields: restConfig.dataFields ? restConfig.dataFields : {},
      variables: restConfig.variables ? restConfig.variables : {},
      responsePaths: restConfig.responsePaths ? restConfig.responsePaths : {},
      dataRaw: restConfig.dataRaw ? restConfig.dataRaw : {},
    };

    setCustomApiConfig(completeNodeConfig);

    if (completeNodeConfig.fetchList.length > 0) {
      setModalSelectedFetchId(completeNodeConfig.fetchList[0].id);
    } else {
      setModalSelectedFetchId(null);
    }

    setIsAddModalVisible(true);
  };

  const handleAddOrUpdate = async () => {
    try {
      const {
        id, // Ensure 'id' is included if updating
        nodeName,
        apiKey,
        description,
        instructions,
        category,
        iconName,
        fetchList,
        headers,
        dataFields,
        variables,
        responsePaths,
        dataRaw,
      } = customApiConfig;
  
      // Construct the restOfConfig object
      const restOfConfig = {
        fetchList,
        headers,
        dataFields,
        variables,
        responsePaths,
        dataRaw,
      };
  
      // Prepare the payload with restOfConfig
      const nodeConfig = {
        id, // Include this if updating an existing node
        nodeName,
        apiKey,
        description,
        instructions,
        category,
        iconName,
        restOfConfig, // Nested configuration
      };
  
      // Determine the endpoint based on whether it's an update or create
      const endpoint = id ? 'update_marketplace_node.php' : 'add_marketplace_node.php';
  
      // Send the POST request
      await axios.post(`https://nodecodestudio.com/backend/${endpoint}`, nodeConfig, {
        headers: { 'Content-Type': 'application/json' },
      });
  
      // Success message
      message.success(id ? 'Marketplace node updated successfully.' : 'Marketplace node added successfully.');
  
      // Close the modal and refresh the nodes
      setIsAddModalVisible(false);
      fetchMarketplaceNodes();
    } catch (error) {
      if (error.response && error.response.data) {
        message.error(`Error: ${error.response.data.error}.`);
      } else {
        console.error('Error adding/updating marketplace node:', error);
        message.error('Failed to add/update Marketplace node. Please try again.');
      }
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.post('https://nodecodestudio.com/backend/delete_marketplace_node.php', { id }, {
        headers: { 'Content-Type': 'application/json' },
      });
      message.success('Marketplace node deleted successfully.');
      fetchMarketplaceNodes();
    } catch (error) {
      console.error('Error deleting marketplace node:', error);
      message.error('Failed to delete marketplace node. Please try again.');
    }
  };

  const handleImportCustomNode = (event) => {
    const fileReader = new FileReader();
    const file = event.target.files[0];

    fileReader.onload = (e) => {
      try {
        const nodeConfig = JSON.parse(e.target.result);

        if (!nodeConfig.nodeName || !Array.isArray(nodeConfig.fetchList)) {
          throw new Error('Invalid custom node configuration.');
        }

        const completeNodeConfig = {
          ...DEFAULT_NODE_CONFIG,
          ...nodeConfig,
          fetchList: nodeConfig.fetchList ? nodeConfig.fetchList : [],
          headers: nodeConfig.headers ? nodeConfig.headers : {},
          dataFields: nodeConfig.dataFields ? nodeConfig.dataFields : {},
          variables: nodeConfig.variables ? nodeConfig.variables : {},
          responsePaths: nodeConfig.responsePaths ? nodeConfig.responsePaths : {},
          dataRaw: nodeConfig.dataRaw ? nodeConfig.dataRaw : {},
        };

        setCustomApiConfig(completeNodeConfig);

        if (completeNodeConfig.fetchList.length > 0) {
          setModalSelectedFetchId(completeNodeConfig.fetchList[0].id);
        } else {
          setModalSelectedFetchId(null);
        }

        setIsAddModalVisible(true);
      } catch (error) {
        console.error('Error importing custom node:', error);
        message.error('Failed to import the file. Please ensure it is a valid custom node JSON file.');
      }
    };

    if (file) {
      fileReader.readAsText(file);
    }
  };

  const handleAddFetchEntry = () => {
    if (newFetchEntryName.trim() === '') return;

    const newEntry = {
      id: uuidv4(),
      name: newFetchEntryName.trim(),
      method: 'GET',
      fetchUrl: '',
    };

    setCustomApiConfig((prevConfig) => ({
      ...prevConfig,
      fetchList: [...prevConfig.fetchList, newEntry],
    }));

    setNewFetchEntryName('');
    setModalSelectedFetchId(newEntry.id);
  };

  const [expandedNodeId, setExpandedNodeId] = useState(null);
// eslint-disable-next-line
  const toggleExpand = (nodeId) => {
    setExpandedNodeId(expandedNodeId === nodeId ? null : nodeId);
  };
// eslint-disable-next-line
  const truncateDescription = (description) => {
    return description.length > 100 ? description.substring(0, 100) + '...' : description;
  };

  const handleExportCustomNode = () => {
    const nodeConfig = customApiConfig;

    const jsonString = `data:text/json;charset=utf-8,${encodeURIComponent(
      JSON.stringify(nodeConfig, null, 2)
    )}`;

    const link = document.createElement('a');
    link.href = jsonString;
    link.download = `${nodeConfig.nodeName || 'custom_node'}.json`;

    link.click();
  };

  // Function to handle opening the detail modal
  const openDetailModal = (node) => {
    setSelectedNode(node);
    setIsDetailModalVisible(true);
  };

  return (
    <div style={{ padding: '20px' }}>
      {userAccess === 'advanced' && (
        <Space style={{ marginBottom: '20px' }}>
          <Button
            type="primary"
            onClick={showAddModal}
            icon={<PlusOutlined />}
          >
            Add
          </Button>
          <Button
            icon={<UploadOutlined />}
            onClick={() => customNodeFileInputRef.current.click()}
          >
            Import
          </Button>
          <input
            type="file"
            accept=".json"
            ref={customNodeFileInputRef}
            style={{ display: 'none' }}
            onChange={handleImportCustomNode}
          />
        </Space>
      )}

<Row gutter={[16, 16]}>
        {marketplaceNodes.map((node) => {
          // Determine actions based on userAccess
          const isAdvanced = userAccess === 'advanced';

          const actions = isAdvanced
            ? [
                <EditOutlined
                  key="edit"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEdit(node);
                  }}
                />,
                <DeleteOutlined
                  key="delete"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete(node.id);
                  }}
                  style={{ color: 'red' }}
                />,
                <ImportOutlined
                  key="import"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleImportToBoard(node.id);
                  }}
                />,
              ]
            : [
                <Button
                  key="import"
                  type="link"
                  icon={<ImportOutlined />}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleImportToBoard(node.id);
                  }}
                  style={{ padding: 0 }}
                >
                  Import
                </Button>,
              ];

          return (
            <Col key={node.id} xs={24} sm={12} md={8} lg={6}>
              <Card
                hoverable
                onClick={() => openDetailModal(node)}
                cover={
                  <div style={{ fontSize: '48px', textAlign: 'center',  }}>
                    {node.iconName && Icons[node.iconName]
                      ? React.createElement(Icons[node.iconName], { size: '2em' })
                      : <Icons.BsCpuFill size="2em" />}
                  </div>
                }
                actions={actions}
              >
                <Meta
                  title={<Title level={4}>{node.nodeName}</Title>}
                  description={
                    <div>
                      <Text type="secondary">{node.category}</Text>
              
                    </div>
                  }
                />
              </Card>
            </Col>
          );
        })}
      </Row>

      {/* Detailed Node Modal */}
      {selectedNode && (
        <Modal
     
          visible={isDetailModalVisible}
          onCancel={() => setIsDetailModalVisible(false)}
          footer={[
            userAccess === 'advanced' && (
              <Button
                key="edit"
                icon={<EditOutlined />}
                onClick={() => {
                  setIsDetailModalVisible(false);
                  handleEdit(selectedNode);
                }}
              >
                Edit
              </Button>
            ),
            <Button
              key="import"
              icon={<ImportOutlined />}
              onClick={() => {
                handleImportToBoard(selectedNode.id);
                setIsDetailModalVisible(false);
              }}
            >
              Import
            </Button>,
            userAccess === 'advanced' && (
              <Button
                key="delete"
                icon={<DeleteOutlined />}
                onClick={() => {
                  handleDelete(selectedNode.id);
                  setIsDetailModalVisible(false);
                }}
                danger
              >
                Delete
              </Button>
            ),
            <Button key="close" onClick={() => setIsDetailModalVisible(false)}>
              Close
            </Button>,
          ]}
          width={800}
        >
          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
            <div><span style={{fontSize:35,paddingTop:40,}}> 
              {selectedNode.iconName && Icons[selectedNode.iconName]
                ? React.createElement(Icons[selectedNode.iconName], )
                : <Icons.BsCpuFill />}</span><span style={{fontSize:40,paddingBottom:40,}}> {selectedNode.nodeName}</span> 
            </div>
            <Typography.Paragraph>
              <strong>Category:</strong> {selectedNode.category}
            </Typography.Paragraph>
            <Typography.Paragraph>
            <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(selectedNode.description),
                }}
              />
       
            </Typography.Paragraph>
            <Typography.Paragraph>
              <strong>Instructions:</strong>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(selectedNode.instructions),
                }}
              />
            </Typography.Paragraph>
     
            {/* You can add more detailed sections here as needed */}
          </Space>
        </Modal>
      )}

      {/* Custom API Modal */}
      <Modal
        title={customApiConfig.id ? "Edit Custom API Node" : "Create Custom API Node"}
        visible={isAddModalVisible}
        onOk={handleAddOrUpdate}
        onCancel={() => setIsAddModalVisible(false)}
        width={800}
        footer={[
          <Button key="import" onClick={() => customNodeFileInputRef.current.click()}>
            Import
          </Button>,
          <Button key="export" onClick={handleExportCustomNode}>
            Export
          </Button>,
          <Button key="cancel" onClick={() => setIsAddModalVisible(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleAddOrUpdate}>
            {customApiConfig.id ? "Update" : "Create"}
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item label="Node Name" required>
            <Input
              value={customApiConfig.nodeName}
              onChange={(e) => setCustomApiConfig({ ...customApiConfig, nodeName: e.target.value })}
              placeholder="e.g., Video Generator"
            />
          </Form.Item>
          <Form.Item label="Description">
            <Input.TextArea
              value={customApiConfig.description}
              onChange={(e) => setCustomApiConfig({ ...customApiConfig, description: e.target.value })}
              placeholder="Enter details about this custom API node"
            />
          </Form.Item>
          <Form.Item label="Category">
            <Input
              value={customApiConfig.category}
              onChange={(e) => setCustomApiConfig({ ...customApiConfig, category: e.target.value })}
              placeholder="e.g., My Custom Nodes"
            />
          </Form.Item>
          <Form.Item label="Icon">
            <Button onClick={() => setIsIconSelectorVisible(true)}>
              {customApiConfig.iconName ? customApiConfig.iconName : 'Select Icon'}
            </Button>
          </Form.Item>
          {isIconSelectorVisible && (
            <Modal
              title="Select Icon"
              open={isIconSelectorVisible}
              onCancel={() => setIsIconSelectorVisible(false)}
              footer={null}
              width={600}
            >
              <IconSelector
                onSelect={(iconName) => {
                  setCustomApiConfig({ ...customApiConfig, iconName });
                  setIsIconSelectorVisible(false);
                }}
                onClose={() => setIsIconSelectorVisible(false)}
              />
            </Modal>
          )}
          <Form.Item label="API Key">
            <Input
              value={customApiConfig.apiKey}
              onChange={(e) =>
                setCustomApiConfig({ ...customApiConfig, apiKey: e.target.value })
              }
              placeholder="Enter your API key"
            />
          </Form.Item>
          <Form.Item label="Instructions">
            <Input.TextArea
              value={customApiConfig.instructions}
              onChange={(e) => setCustomApiConfig({ ...customApiConfig, instructions: e.target.value })}
              placeholder="Provide instructions on how to obtain an API key (HTML allowed)"
            />
          </Form.Item>

          {/* Add Fetch Entries */}
          <Form.Item label="Add Fetch Entry">
            <Input
              value={newFetchEntryName}
              onChange={(e) => setNewFetchEntryName(e.target.value)}
              placeholder="Enter Fetch Entry Name"
            />
            <Button onClick={handleAddFetchEntry} style={{ marginTop: '8px' }}>
              Add Fetch Entry
            </Button>
          </Form.Item>

          {/* Select Fetch Entry to Configure */}
          {customApiConfig.fetchList.length > 0 && (
            <Form.Item label="Select Fetch Entry to Configure">
              <Select
                value={modalSelectedFetchId}
                onChange={(value) => setModalSelectedFetchId(value)}
              >
                {customApiConfig.fetchList.map((entry) => (
                  <Option key={entry.id} value={entry.id}>
                    {entry.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {modalSelectedFetchId && (
            <>
              {/* Configure Selected Fetch Entry */}
              <Form.Item label="Fetch Entry Name">
                <Input
                  value={
                    customApiConfig.fetchList.find(
                      (entry) => entry.id === modalSelectedFetchId
                    )?.name || ''
                  }
                  onChange={(e) => {
                    const newFetchList = customApiConfig.fetchList.map((entry) =>
                      entry.id === modalSelectedFetchId
                        ? { ...entry, name: e.target.value }
                        : entry
                    );
                    setCustomApiConfig({ ...customApiConfig, fetchList: newFetchList });
                  }}
                />
              </Form.Item>

              {/* Request Method */}
              <Form.Item label="Request Method">
                <Select
                  value={
                    customApiConfig.fetchList.find(
                      (entry) => entry.id === modalSelectedFetchId
                    )?.method || 'GET'
                  }
                  onChange={(value) => {
                    const newFetchList = customApiConfig.fetchList.map((entry) =>
                      entry.id === modalSelectedFetchId
                        ? { ...entry, method: value }
                        : entry
                    );
                    setCustomApiConfig({ ...customApiConfig, fetchList: newFetchList });
                  }}
                >
                  {['GET', 'POST', 'PUT', 'PATCH', 'DELETE'].map((method) => (
                    <Option key={method} value={method}>
                      {method}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              {/* Fetch URL */}
              <Form.Item label="Fetch URL">
                <Input
                  value={
                    customApiConfig.fetchList.find(
                      (entry) => entry.id === modalSelectedFetchId
                    )?.fetchUrl || ''
                  }
                  onChange={(e) => {
                    const newFetchList = customApiConfig.fetchList.map((entry) =>
                      entry.id === modalSelectedFetchId
                        ? { ...entry, fetchUrl: e.target.value }
                        : entry
                    );
                    setCustomApiConfig({ ...customApiConfig, fetchList: newFetchList });
                  }}
                />
              </Form.Item>

              {/* Headers Table */}
              <Form.Item label="Headers">
                <HeadersTable
                  headers={customApiConfig.headers[modalSelectedFetchId] || []}
                  setHeaders={(headers) =>
                    setCustomApiConfig({
                      ...customApiConfig,
                      headers: {
                        ...customApiConfig.headers,
                        [modalSelectedFetchId]: headers,
                      },
                    })
                  }
                />
              </Form.Item>

              {/* Data Fields Table */}
              <Form.Item label="Data Fields">
                <DataFieldsTable
                  dataFields={customApiConfig.dataFields[modalSelectedFetchId] || []}
                  setDataFields={(dataFields) =>
                    setCustomApiConfig({
                      ...customApiConfig,
                      dataFields: {
                        ...customApiConfig.dataFields,
                        [modalSelectedFetchId]: dataFields,
                      },
                    })
                  }
                />
              </Form.Item>

              {/* Variables Table */}
              <Form.Item label="Variables">
                <VariablesTable
                  variables={customApiConfig.variables[modalSelectedFetchId] || []}
                  setVariables={(variables) =>
                    setCustomApiConfig({
                      ...customApiConfig,
                      variables: {
                        ...customApiConfig.variables,
                        [modalSelectedFetchId]: variables,
                      },
                    })
                  }
                />
              </Form.Item>

              {/* Response Paths Table */}
              <Form.Item label="Response Extraction Paths">
                <ResponsePathsTable
                  responsePaths={customApiConfig.responsePaths[modalSelectedFetchId] || []}
                  setResponsePaths={(paths) =>
                    setCustomApiConfig({
                      ...customApiConfig,
                      responsePaths: {
                        ...customApiConfig.responsePaths,
                        [modalSelectedFetchId]: paths,
                      },
                    })
                  }
                />
              </Form.Item>

              {/* Data Raw Payload */}
              {['POST', 'PUT', 'PATCH', 'DELETE'].includes(
                customApiConfig.fetchList.find(
                  (entry) => entry.id === modalSelectedFetchId
                )?.method || ''
              ) && (
                <Form.Item label="Data Raw Payload (JSON)">
                  <Input.TextArea
                    value={customApiConfig.dataRaw[modalSelectedFetchId] || ''}
                    onChange={(e) =>
                      setCustomApiConfig({
                        ...customApiConfig,
                        dataRaw: {
                          ...customApiConfig.dataRaw,
                          [modalSelectedFetchId]: e.target.value,
                        },
                      })
                    }
                    placeholder='e.g., {"key":"value"}'
                  />
                </Form.Item>
              )}
            </>
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default Marketplace;