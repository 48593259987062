// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { useAuth0, Auth0Provider } from '@auth0/auth0-react';
import LoggedIn from './LoggedIn';
import LoggedOut from './LoggedOut';
import NodeCreator from './NodeCreator'; // Import NodeCreator
import { Button, Navbar, Nav } from 'react-bootstrap';
import { AuthProvider } from './AuthContext'; // Import AuthProvider
import './App.css';
import Marketplace from './Marketplace';
// eslint-disable-next-line
import ReactFlow, {

  ReactFlowProvider,

} from 'react-flow-renderer';
const App = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  return (
    <Router>
      <div>
        <div className="navbar-wrapper">
          <Navbar expand="lg" className="app-navbar">
            <div className="navbar-content">
              <Navbar.Brand as={Link} to="/">
                <img src="logowhite.png" alt="Logo" className="header-logo" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto d-flex align-items-center">
                  {!isAuthenticated ? (
                    <Nav.Item>
                      <Button onClick={() => loginWithRedirect()} variant="primary">
                        Login
                      </Button>
                    </Nav.Item>
                  ) : (
                    <>
                      {/* Home Button */}
                      <Nav.Item>
                        <Button
                          as={Link}
                          to="/"
                          variant="info"
                          className="me-2 btnnavstyle homebtnstyle"
                        >
                          Home
                        </Button>
                      </Nav.Item>

               

                      {/* Logout Button */}
                      <Nav.Item>
                        <Button
                          onClick={() => logout({ returnTo: window.location.origin })}
                          variant="secondary"
                        >
                          Logout
                        </Button>
                      </Nav.Item>
                    </>
                  )}
                </Nav>
              </Navbar.Collapse>
            </div>
          </Navbar>
        </div>

        <Routes>
          <Route path="/" element={isAuthenticated ? <LoggedIn /> : <LoggedOut />} />
          <Route path="/node-creator" element={isAuthenticated ? <NodeCreator /> : <LoggedOut />} />
          <Route path="/marketplace" element={<Marketplace />} />
          </Routes>
      </div>
    </Router>
  );
};

const AppWrapper = () => {
  return (
    <ReactFlowProvider>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE} // Add your API identifier here
      scope="read:current_user" // Adjust scopes as needed
    >
      <AuthProvider> {/* Wrap with AuthProvider */}
        <App />
      </AuthProvider>
    </Auth0Provider>
    </ReactFlowProvider>
  );
};

export default AppWrapper;

