// src/components/DataFieldsTable.js

import React from 'react';
import { Table, Input, Button, Checkbox, Select } from 'antd';

const DataFieldsTable = ({ dataFields, setDataFields }) => {
  const { Option } = Select;

  const columns = [
    {
      title: 'Field Name',
      dataIndex: 'name',
      render: (text, record, index) => (
        <Input
          value={text}
          onChange={(e) => handleFieldChange(index, 'name', e.target.value)}
        />
      ),
    },
    {
      title: 'Field Value',
      dataIndex: 'value',
      render: (text, record, index) => (
        <Input
          value={text}
          onChange={(e) => handleFieldChange(index, 'value', e.target.value)}
          disabled={record.isEntryPoint}
        />
      ),
    },
    {
      title: 'Data Type',
      dataIndex: 'dataType',
      render: (text, record, index) => (
        <Select
          value={text || 'string'}
          onChange={(value) => handleFieldChange(index, 'dataType', value)}
          style={{ width: '100%' }}
        >
          <Option value="string">String</Option>
          <Option value="number">Number</Option>
          <Option value="boolean">Boolean</Option>
          <Option value="object">Object</Option>
          <Option value="array">Array</Option>
        </Select>
      ),
    },
    {
      title: 'Is Entry Point',
      dataIndex: 'isEntryPoint',
      render: (text, record, index) => (
        <Checkbox
          checked={record.isEntryPoint}
          onChange={(e) =>
            handleFieldChange(index, 'isEntryPoint', e.target.checked)
          }
        />
      ),
    },
    {
      title: 'Actions',
      render: (_, __, index) => (
        <Button onClick={() => handleDeleteField(index)}>Delete</Button>
      ),
    },
  ];

  const handleFieldChange = (index, key, value) => {
    const newFields = [...dataFields];
    newFields[index][key] = value;
    setDataFields(newFields);
  };

  const handleAddField = () => {
    setDataFields([
      ...dataFields,
      { name: '', value: '', isEntryPoint: false, dataType: 'string' },
    ]);
  };

  const handleDeleteField = (index) => {
    const newFields = [...dataFields];
    newFields.splice(index, 1);
    setDataFields(newFields);
  };

  return (
    <div>
      <Table
        dataSource={dataFields}
        columns={columns}
        pagination={false}
        rowKey={(record, index) => index}
      />
      <Button onClick={handleAddField} style={{ marginTop: '10px' }}>
        Add Field
      </Button>
    </div>
  );
};

export default DataFieldsTable;
