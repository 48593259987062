import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import './nodecreatorstyle.css';
import Marketplace from './Marketplace'; // Adjust the import path if necessary
import { useStore } from 'react-flow-renderer';
import ReactFlow, {
  addEdge,
  Background,
  Controls,
  MiniMap,
  useNodesState,
  useEdgesState,
  ReactFlowProvider,
  useReactFlow,
  Handle, // Add Handle here
} from 'react-flow-renderer';
import * as Icons from 'react-icons/bs';
import { Modal, Input, Form, Button, Spin, Select,   message } from 'antd';
import 'antd/dist/reset.css';
import { v4 as uuidv4 } from 'uuid';
import HeadersTable from './components/HeadersTable';
import DataFieldsTable from './components/DataFieldsTable';
import Sidebar from './components/Sidebar';
import ImageNode from './components/nodes/ImageNode';
import DecisionNode from './components/nodes/DecisionNode';
import VariablesTable from './components/VariablesTable'; // Import the VariablesTable
import ResponsePathsTable from './components/ResponsePathsTable';
import IconSelector from './components/IconSelector';
import RegexNode from './components/nodes/RegexNode';
import { DEFAULT_REGEX_COMMANDS } from './constants/regexCommands'; // Import the constants
import axios from 'axios';
import { useLocation } from 'react-router-dom'; // Import useLocation for query params
import {
  PromptEntryNode,
  LogNode,
  SplitNode,
  CombineNode,
  ShapeTextNode,
  ShapeNode
} from './components/nodes';


import {
  getNodeById,
  getIncomingEdges,
} from './utils/helpers';

import { executeNode } from './utils/executeNode';


const NodeCreator = () => {
  // eslint-disable-next-line
  const [userAccess, setUserAccess] = useState(localStorage.getItem('userAccess') || '');

  const location = useLocation(); // To access query parameters
  const query = new URLSearchParams(location.search);
  const boardId = query.get('id'); // Get the board_id from query params
  
  const [isIconSelectorVisible, setIsIconSelectorVisible] = useState(false);
  // eslint-disable-next-line
  const resetExecutionState = () => {
    setNodes((nds) =>
      nds.map((n) => ({
        ...n,
        data: {
          ...n.data,
          isProcessing: false,
          isExecuted: false,
          isWaiting: false,
          executionData: null,
        },
      }))
    );

    nodeInputDataRef.current = {};
  };
  const [promptMessage, setPromptMessage] = useState('Enter a prompt');
  const userId = localStorage.getItem('userId');
  // eslint-disable-next-line
  const handleDeleteNode = (nodeId) => {
    console.log('Deleting node:', nodeId);
    setNodes((nds) => nds.filter((node) => node.id !== nodeId));
    setEdges((eds) => eds.filter((edge) => edge.source !== nodeId && edge.target !== nodeId));
  };
  const nodeExecutionStateRef = useRef({});
  const [showInstructions, setShowInstructions] = useState(false);
  const toggleInstructions = () => {
    setShowInstructions((prev) => !prev);
  };
  useEffect(() => {
    const loadBoard = async () => {
      if (boardId && userId) {
        try {
          const response = await axios.get('https://nodecodestudio.com/backend/get_board.php', {
            params: { board_id: boardId, user_id: userId },
            headers: {
              'Content-Type': 'application/json',
            },
          });
  
          const { board_data, custom_nodes } = response.data;
  
          // Process and register custom node definitions
          if (custom_nodes && custom_nodes.length > 0) {
            custom_nodes.forEach((customNode) => {
              const config = customNode.rest_of_config;
  
              // Set apiKey if needed
              config.apiKey = customNode.api_key;
  
              // Merge marketplace_info into config, including market_id
              if (customNode.marketplace_info) {
                config.nodeName = customNode.marketplace_info.node_name;
                config.description = customNode.marketplace_info.description;
                config.category = customNode.marketplace_info.category;
                config.iconName = customNode.marketplace_info.icon;
                config.instructions = customNode.marketplace_info.instructions;
                config.market_id = customNode.marketplace_info.market_id; // Include market_id here
              }
  
              registerOrUpdateCustomNodeType(config);
            });
          }
  
          // Process loaded nodes to attach deleteNode and other necessary functions
          const processedNodes = (board_data.nodes || []).map((node) => {
            // For custom API nodes, ensure data.config is set
            if (node.type.startsWith('customApiNode_')) {
              const config = customApiNodeConfigs[node.type];
              if (config) {
                node.data.config = config;
              }
            }
  
            return {
              ...node,
              data: {
                ...node.data,
                deleteNode: () => handleDeleteNode(node.id),
                onTextChange: node.data.onTextChange || ((newText) => onTextChange(node.id, newText)),
                // Add any other necessary functions or properties here
              },
            };
          });
  
          // Set the processed nodes and edges
          setNodes(processedNodes);
          setEdges(board_data.edges || []);
  
          console.log('Board loaded successfully with custom nodes.');
        } catch (err) {
          console.error('Error loading board:', err);
          alert(
            err.response && err.response.data && err.response.data.error
              ? `Error: ${err.response.data.error}`
              : 'Failed to load board. Please try again.'
          );
        }
      }
    };
  
    if (userId && boardId) {
      loadBoard();
    }
    // eslint-disable-next-line
  }, [userId, boardId]);
  // eslint-disable-next-line
  const [selectedFetchName, setSelectedFetchName] = useState(null);  const [nodes, setNodes, onNodesChange] = useNodesState([]); // Start with an empty canvas
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [promptValue, setPromptValue] = useState('');
  const nodeInputDataRef = useRef({});
  const reactFlowWrapper = useRef(null);
  const [isMarketplaceVisible, setIsMarketplaceVisible] = useState(false);
  const [customApiNodeConfigs, setCustomApiNodeConfigs] = useState({});
  const { project } = useReactFlow(); // Use the `project` method to convert coordinates
  const [newFetchEntryName, setNewFetchEntryName] = useState('');
  // Helper functions specific to App.js
  const [modalSelectedFetchId, setModalSelectedFetchId] = useState(null);
  const getNodeByIdLocal = (nodeId) => getNodeById(nodes, nodeId);
  
  const getConnectedNodesLocal = (nodeId, handleId = null) => {
    const outgoingEdges = edges.filter(
      (edge) =>
        edge.source === nodeId && (handleId === null || edge.sourceHandle === handleId)
    );
    return outgoingEdges.map((edge) => edge.target);
  };
  const getOutgoingEdgesLocal = (nodeId) => edges.filter((edge) => edge.source === nodeId);
  const getIncomingEdgesLocal = (nodeId) => getIncomingEdges(edges, nodeId);
// State for Custom API Modal
const [isCustomApiModalVisible, setIsCustomApiModalVisible] = useState(false);
const [customApiConfig, setCustomApiConfig] = useState({
  nodeName: '',
  category: '',
  apiKey: '',
  fetchList: [],      // Array of fetch entries
  headers: {},        // Object: { fetchId: array of headers }
  dataFields: {},     // Object: { fetchId: array of data fields }
  variables: {},      // Object: { fetchId: array of variables }
  responsePaths: {},  // Object: { fetchId: array of response paths }
  dataRaw: {},        // Object: { fetchId: string }
});
// Function to show the custom API modal
const showCustomApiModal = () => {
  setCustomApiConfig({
    nodeName: '',
    apiKey: '',
    fetchList: [],
    headers: {},
    dataFields: {},
    variables: {},
    responsePaths: {},
    dataRaw: {},
  });
  setModalSelectedFetchId(null); // No fetch entries yet
  setIsCustomApiModalVisible(true);
};
// eslint-disable-next-line
const onTextChange = (nodeId, newText) => {
  setNodes((nds) =>
    nds.map((node) => {
      if (node.id === nodeId) {
        return {
          ...node,
          data: {
            ...node.data,
            popupText: newText,
          },
        };
      }
      return node;
    })
  );
};

useEffect(() => {
  // Add event listener to handle save event from the sidebar
  const saveBoardListener = () => {
    handleSave();
  };

  window.addEventListener('saveBoard', saveBoardListener);

  // Clean up the event listener
  return () => {
    window.removeEventListener('saveBoard', saveBoardListener);
  };
  // eslint-disable-next-line
}, [nodes, edges, customApiNodeConfigs]); // Make sure to include relevant dependencies
const handleSave = async () => {
  try {
    // Collect all custom node configurations
    const customNodeDefinitions = Object.values(customApiNodeConfigs);

    // Prepare nodes for saving, excluding unnecessary data fields
    const nodesToSave = nodes.map((node) => {
      const { data, ...rest } = node;
      // Exclude 'apiResult', 'isProcessing', and other runtime properties
      const {
        apiResult,
        isProcessing,
        executionData,
        isWaiting,
        decisionMade,
        // Add any other fields you want to exclude
        ...dataWithoutRuntime
      } = data;
      return {
        ...rest,
        data: dataWithoutRuntime,
      };
    });

    // Prepare save data
    const flowData = {
      nodes: nodesToSave,
      edges,
      customNodeDefinitions,
    };

    // Retrieve userId from localStorage
    const userId = localStorage.getItem('userId');

    if (!userId) {
      alert('User ID is missing. Please make sure you are logged in.');
      return;
    }

    const saveData = {
      user_id: userId,
      board_data: flowData,
      board_id: boardId, // Include board_id if it exists
    };

    // Make the POST request to save the board data
    const response = await axios.post('https://nodecodestudio.com/backend/saveBoard.php', saveData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
      alert('Board saved successfully.');
      console.log(response.data);
    } else {
      throw new Error('Failed to save the board. Please try again.');
    }
  } catch (error) {
    console.error('Error saving the board:', error);
    alert('An error occurred while saving the board. Please check the console for more details.');
  }
};

const startProcessing = async () => {
  console.log('Starting flow without prompt input.');

  // Reset node data, including specific logic for 'decisionNode' and 'customApiNode_'
  setNodes((nds) =>
    nds.map((node) => {
      // For 'decisionNode', reset additional properties like 'isWaiting' and 'executionData'
      if (node.type === 'decisionNode') {
        return {
          ...node,
          data: {
            ...node.data,
            isWaiting: false,
            executionData: null,
            apiResult: '',
            logContent: '',
            combinedData: '',
            isProcessing: false,
          },
        };
      }

      // For custom API nodes, reset 'fieldValues' and other runtime properties, but preserve 'variables'
      if (node.type.startsWith('customApiNode_')) {
        return {
          ...node,
          data: {
            ...node.data,
            apiResult: '', // Reset API result
            isProcessing: false, // Reset processing state
          },
        };
      }

      // For other node types, reset the generic node data
      return {
        ...node,
        data: {
          ...node.data,
          apiResult: '',
          logContent: '',
          combinedData: '',
          isProcessing: false,
        },
      };
    })
  );

  // Initialize node input data
  nodeInputDataRef.current = {};

  // Start the flow from the starting nodes (nodes with no incoming edges)
  const startingNodes = nodes.filter((node) => getIncomingEdgesLocal(node.id).length === 0);

  for (const node of startingNodes) {
    // Handle different entry node types
    if (node.type === 'promptEntry') {
      nodeInputDataRef.current[node.id] = [promptValue];
    } else if (node.type === 'imageNode') {
      // Assuming `imageInput` is a state where you gather the image URL or input
      const imageInput = node.data.imageUrl || 'defaultImageInput';
      nodeInputDataRef.current[node.id] = [imageInput];
    }

    await executeNode({
      nodeId: node.id,
      getNodeById: getNodeByIdLocal,
      getIncomingEdges: getIncomingEdgesLocal,
      getConnectedNodes: getConnectedNodesLocal,
      getOutgoingEdges: getOutgoingEdgesLocal, // **Added this line**

      setNodes,
      nodeInputDataRef,
      promptValue,
      nodeExecutionStateRef,
      handleApiCall,
      executeNode, // Pass the function itself for recursion
    });
  }
};
const handleAddFetchEntry = () => {
  if (newFetchEntryName.trim() === '') {
    // Optionally show an error message here
    return;
  }

  const newEntry = {
    id: uuidv4(),
    name: newFetchEntryName.trim(),
    method: 'GET',
    fetchUrl: '',
  };

  setCustomApiConfig((prevConfig) => ({
    ...prevConfig,
    fetchList: [...prevConfig.fetchList, newEntry],
  }));

  // Clear the input and set the selected fetch ID
  setNewFetchEntryName('');
  setModalSelectedFetchId(newEntry.id);

};
 // **Add the isValidJson state here**
 // eslint-disable-next-line
 const [isValidJson, setIsValidJson] = useState(true);

 // **Add form validity state**
 const [isValidForm, setIsValidForm] = useState(true);

 // **Form Validation Effect**
 useEffect(() => {
  const jsonIsValid =
    customApiConfig.method &&
    !['GET', 'DELETE'].includes(customApiConfig.method.toUpperCase())
      ? isValidJson
      : true; // Only validate JSON if method supports a body
  setIsValidForm(jsonIsValid);
}, [customApiConfig.method, isValidJson]);


const handleCustomApiOk = async () => {
  // Validate the form or customApiConfig as needed
  if (!isValidForm) {
    message.error('Please fill in all required fields.');
    return;
  }

  try {
    if (editingNodeType) {
      // Updating an existing custom API node
      const config = { ...customApiConfig };

      // Extract market_id from the config
      const marketId = config.market_id || config.marketId;

      if (!marketId || !boardId || !userId) {
        console.error('market_id, board_id, or user_id is missing.');
        message.error('Failed to update custom node. Missing identifiers.');
        return;
      }

      // Prepare the payload
      const payload = {
        user_id: userId,
        board_id: boardId,
        market_id: marketId,
        apiKey: config.apiKey || '',
        rest_of_config: JSON.stringify(config), // Convert config to JSON string
      };

      // Send the update request to the backend
      const response = await axios.post(
        'https://nodecodestudio.com/backend/update_custom_node.php',
        payload,
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );

      if (response.data && response.data.message) {
        message.success(response.data.message);
      } else {
        message.success('Custom node updated successfully.');
      }

      // Update the customApiNodeConfigs with the new config
      setCustomApiNodeConfigs((prevConfigs) => ({
        ...prevConfigs,
        [editingNodeType]: config,
      }));

      // Close the modal
      setIsCustomApiModalVisible(false);
    } else {
      // Handle creation of a new custom API node (existing code)
      // ...
    }
  } catch (error) {
    console.error('Error updating custom node:', error);
    message.error('Failed to update custom node. Please try again.');
  }
};
// Inside your App.js

// In your App.js or the appropriate file

// Function to register or update custom node types
const registerOrUpdateCustomNodeType = (config, nodeTypeName = null) => {
  if (!nodeTypeName) {
    const nodeName = config.nodeName;
    nodeTypeName = `customApiNode_${nodeName.replace(/\s+/g, '_')}`;
  }
  if (!config.market_id && config.marketId) {
    config.market_id = config.marketId;
  }


  // Create a new React component for the node
  const CustomApiNodeComponent = ({ data, id }) => {
    const { setNodes } = useReactFlow();
  
    // Subscribe to edge changes
    const edges = useStore((state) => state.edges);
  
    const {
      isProcessing = false,
      variables = {},
      apiResult,
      config,
      fieldValues = {},
      selectedFetchId,
      fetchList,
    } = data;
    const IconComponent = config && config.iconName ? Icons[config.iconName] : null;

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [entryPoints, setEntryPoints] = useState([]);
    useEffect(() => {
      // Extract entry points whenever selectedFetchId or config changes
      if (selectedFetchId && config) {
        const variablesForFetch = config.variables[selectedFetchId] || [];
        const dataFieldsForFetch = config.dataFields[selectedFetchId] || [];
  
        const newEntryPoints = [
          ...variablesForFetch.filter((variable) => variable.isEntryPoint),
          ...dataFieldsForFetch.filter((field) => field.isEntryPoint),
        ];
  
        setEntryPoints(newEntryPoints);
      }
    }, [selectedFetchId, config]);
    const handleEditClick = () => {
      setIsModalVisible(true);
    };

    const handleModalOk = () => {
      setIsModalVisible(false);
    };

    const handleModalCancel = () => {
      setIsModalVisible(false);
    };
 // Find the selected fetch entry
 // eslint-disable-next-line
 const selectedFetchEntry = fetchList.find((entry) => entry.id === selectedFetchId) || {};
 const variablesForFetch = config.variables[selectedFetchId] || [];
 // eslint-disable-next-line
 const dataFieldsForFetch = config.dataFields[selectedFetchId] || [];
 
 // Extract entry points (variables and data fields) marked as isEntryPoint: true

  // Function to check if a handle is connected
  const isHandleConnected = (handleId) => {
    return edges.some(
      (edge) =>
        (edge.source === id && edge.sourceHandle === handleId) ||
        (edge.target === id && edge.targetHandle === handleId)
    );
  };
 // Handler for input changes in entry points
 const handleEntryPointChange = (entryPointName, value) => {
  // Determine if the entry point is a variable or a data field
  const isVariable = variablesForFetch.some(
    (variable) => variable.name === entryPointName && variable.isEntryPoint
  );

  if (isVariable) {
    // Update variables
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: {
              ...node.data,
              variables: {
                ...node.data.variables,
                [entryPointName]: {
                  ...(node.data.variables[entryPointName] || {}),
                  value: value,
                },
              },
            },
          };
        }
        return node;
      })
    );
  } else {
    // Update data fields
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: {
              ...node.data,
              fieldValues: {
                ...node.data.fieldValues,
                [entryPointName]: value,
              },
            },
          };
        }
        return node;
      })
    );
  }
};

// eslint-disable-next-line
// Handle fetch entry selection change
const handleFetchIdChange = (value) => {
  // Find the fetch entry in the config
  // eslint-disable-next-line
  const fetchEntry = customApiConfig.fetchList.find((entry) => entry.id === value);

  // Variables specific to the selected fetch entry
  const variablesForFetch = customApiConfig.variables[value] || [];
  const newVariables = {};

  // Preserve any existing entry point values
  variablesForFetch.forEach((variable) => {
    newVariables[variable.name] = {
      value: variable.value || '', // Set default value if not already set
      dataType: variable.dataType,
      isEntryPoint: variable.isEntryPoint,
    };
  });

  // Data fields specific to the selected fetch entry
  const dataFieldsForFetch = customApiConfig.dataFields[value] || [];
  const newFieldValues = {};

  // Set field values for the selected fetch entry
  dataFieldsForFetch.forEach((field) => {
    newFieldValues[field.name] = field.value !== undefined ? field.value : '';
  });

  // Update the node's variables and field values based on the selected fetch entry
  setNodes((nds) =>
    nds.map((node) => {
      if (node.id === id) {
        return {
          ...node,
          data: {
            ...node.data,
            selectedFetchId: value, // Update selected fetch ID
            variables: newVariables, // Set variables specific to this fetch entry
            fieldValues: newFieldValues, // Set field values specific to this fetch entry
          },
        };
      }
      return node;
    })
  );
};

   // **Define handleVariableChange and handleFieldValueChange**
const handleVariableChange = (varName, value) => {
  setNodes((nds) =>
    nds.map((node) => {
      if (node.id === id) {
        return {
          ...node,
          data: {
            ...node.data,
            variables: {
              ...node.data.variables,
              [varName]: {
                ...node.data.variables[varName],
                value: value,
              },
            },
          },
        };
      }
      return node;
    })
  );
};
const handleFieldValueChange = (fieldName, value) => {
  setNodes((nds) =>
    nds.map((node) => {
      if (node.id === id) {
        return {
          ...node,
          data: {
            ...node.data,
            fieldValues: {
              ...node.data.fieldValues,
              [fieldName]: value,
            },
          },
        };
      }
      return node;
    })
  );
};

    // Function to determine if apiResult is an image Data URL
    const isImage = (content) => {
      return typeof content === 'string' && content.startsWith('data:image/');
    };

    return (
      <div className="node-border">
        {isProcessing && (
          <div
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              right: '0',
              bottom: '0',
              backgroundColor: 'rgba(255,255,255,0.7)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 10,
            }}
          >
            <Spin />
          </div>
        )}

        {/* Display the node's title */}
        <div className='topBar'>   {IconComponent && <IconComponent className="nodeIcon" />}
        <span className='node-title'>
      

         {config.nodeName}</span>
         <span >
         <span
         style={{marginRight:10}}
          className="node-delete"
            onClick={handleEditClick}
            title="Edit Node"
          >
            ✎
          </span>
         <span
          className="node-delete"
          onClick={data.deleteNode}
        >
          x
        </span></span>
        </div>

        {/* Dropdown to select fetch entry */}
        <Select
  className="nodrag"
  value={selectedFetchId}
  onChange={(value) => handleFetchIdChange(value)} // Updated line
  placeholder="Select Fetch Entry"
  style={{ width: '100%', marginTop: '10px' }}
>
  {fetchList.map((fetchEntry) => (
    <Select.Option key={fetchEntry.id} value={fetchEntry.id}>
      {fetchEntry.name}
    </Select.Option>
  ))}
</Select>


 {/* Display Entry Points with Round Input Fields */}
 {selectedFetchId && entryPoints.length > 0 && (
        <div style={{ marginTop: '10px' }}>
          {entryPoints.map((entryPoint, index) => {
            const handleId = `input-${entryPoint.name}`;
            const connected = isHandleConnected(handleId);

            return (
              <div
                key={index}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '8px',
                  position: 'relative', // Position the Handle absolutely
                }}
              >
                {/* Handle for this entry point */}
                <Handle
                  type="target"
                  position="left"
                  id={handleId}
                  style={{
                    position: 'absolute',
                    left: '-15px', // Adjust as needed
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                  onConnect={(params) => console.log('Handle onConnect', params)}
                />

                {/* Round Input Indicator */}
                <div
                  style={{
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%',
                    backgroundColor: '#1890ff',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#fff',
                    marginRight: '10px',
                    flexShrink: 0,
                    fontWeight: 'bold',
                  }}
                >
                  {entryPoint.name.charAt(0).toUpperCase()}
                </div>

                {/* Entry Point Label and Input */}
                <div style={{ flexGrow: 1 }}>
                  <span style={{ marginRight: '5px' }}>{entryPoint.name}:</span>
                  <Input
                    value={
                      variablesForFetch.some(
                        (variable) => variable.name === entryPoint.name && variable.isEntryPoint
                      )
                        ? variables[entryPoint.name]?.value || ''
                        : fieldValues[entryPoint.name] || ''
                    }
                    onChange={(e) => handleEntryPointChange(entryPoint.name, e.target.value)}
                    placeholder={`Enter value for ${entryPoint.name}`}
                    disabled={connected} // Disable if connected
                    style={{
                      backgroundColor: connected ? '#f0f0f0' : 'white', // Greyed out background
                      cursor: connected ? 'not-allowed' : 'text', // Change cursor
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
      
       

     

        {/* Display the API result */}
        {apiResult && (
  <div
    className="nodrag scrollable-content" // Applying the scrollable-content class
    style={{
      marginTop: '10px',
      whiteSpace: 'pre-wrap',
      height: '150px',   // Fixed height to ensure scrollable behavior
      overflowY: 'scroll',  // Enable vertical scrolling, always show scrollbar
      overflowX: 'hidden',  // Disable horizontal scrolling
      border: '1px solid #ddd',
      padding: '10px',
      backgroundColor: '#f9f9f9',
      cursor: 'default' // Ensure default cursor to prevent drag interference
    }}
  >
    {isImage(apiResult) ? (
      <img
        src={apiResult}
        alt="API Result"
        style={{ maxWidth: '100%', maxHeight: '100px' }}
      />
    ) : (
      <div>
        {typeof apiResult === 'string'
          ? apiResult
          : JSON.stringify(apiResult, null, 2)}
      </div>
    )}
  </div>
)}
   {entryPoints.length === 0 && (
  <Handle
    type="target"
    position="left"
    id="input"
    style={{ top: '50%', transform: 'translateY(-50%)', position: 'absolute' }}
  />
)}
        <Handle
          type="source"
          position="right"
          id="output"
          style={{ top: '50%', transform: 'translateY(-50%)', position: 'absolute' }}
        />

        {/* Modal for editing variables and data fields */}
        <Modal
          title={`Edit ${config.nodeName} Node`}
          open={isModalVisible}
          onOk={handleModalOk}
          onCancel={handleModalCancel}
        >
          <Form layout="vertical">
            {/* Variables Section */}
            <Form.Item>
              {Object.keys(variables)
                .filter((varName) => !variables[varName].isEntryPoint).length > 0 ? (
                Object.keys(variables)
                  .filter((varName) => !variables[varName].isEntryPoint)
                  .map((varName) => (
                    <Form.Item label={varName} key={varName}>
                      <Input
                        value={variables[varName].value}
                        onChange={(e) => handleVariableChange(varName, e.target.value)}
                        placeholder={`Enter value for ${varName}`}
                      />
                    </Form.Item>
                  ))
              ) : (
               <></>
              )}
            </Form.Item>

            {/* Data Fields Section */}
            <Form.Item label="">
              {config.dataFields[selectedFetchId] &&
              config.dataFields[selectedFetchId].filter((field) => !field.isEntryPoint)
                .length > 0 ? (
                config.dataFields[selectedFetchId]
                  .filter((field) => !field.isEntryPoint)
                  .map((field) => (
                    <Form.Item label={field.name} key={field.name}>
                      <Input
                        value={fieldValues[field.name] || ''}
                        onChange={(e) => handleFieldValueChange(field.name, e.target.value)}
                        placeholder={`Enter value for ${field.name}`}
                      />
                    </Form.Item>
                  ))
              ) : (
              <></>
              )}
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  };

  // Default data for the node
  CustomApiNodeComponent.defaultData = {
    isProcessing: false,
    deleteNode: () => {},
    config, // Attach the configuration to the node's data
    variables: {}, // Initialized during node creation
    fieldValues: {}, // For non-variable fields
    apiResult: '', // Initialize as empty
    dataRaw: '', // For raw payloads
  };
  console.log('CustomApiNodeComponent - config:', config);
  // Update nodeTypes
  setCustomApiNodeTypes((prevTypes) => ({
    ...prevTypes,
    [nodeTypeName]: CustomApiNodeComponent,
  }));

  // Store the configuration
  setCustomApiNodeConfigs((prevConfigs) => ({
    ...prevConfigs,
    [nodeTypeName]: config,
  }));

  // Add the new node to the sidebar if not already present
  setCustomNodesList((prevList) => {
    const existingNodeIndex = prevList.findIndex((node) => node.type === nodeTypeName);
    const newNode = {
      type: nodeTypeName,
      label: config.nodeName,
      category: config.category,
      iconName: config.iconName, // Ensure iconName is included
    };

    if (existingNodeIndex !== -1) {
      // Update existing node
      const updatedList = [...prevList];
      updatedList[existingNodeIndex] = newNode;
      return updatedList;
    } else {
      // Add new node
      return [...prevList, newNode];
    }
  });
};

const handleCustomApiCancel = () => {
  setIsCustomApiModalVisible(false);
};

const [customApiNodeTypes, setCustomApiNodeTypes] = useState({});
// Register node types
const nodeTypes = useMemo(
  () => ({

    promptEntry: PromptEntryNode,
    log: LogNode,
    splitNode: SplitNode,
    combineNode: CombineNode,
    imageNode: ImageNode, 
    decisionNode: DecisionNode,
    regexNode: RegexNode,
    shapeTextNode: ShapeTextNode,
    shapeNode: ShapeNode,
    ...customApiNodeTypes,
  }),
  [customApiNodeTypes]
);










  // Function to handle API call
  const handleApiCall = async (inputData, nodeId) => {
    try {
      const node = getNodeByIdLocal(nodeId);
      const roleContent = node.data.roleContent || '';
      let content2 = inputData;
      if (typeof inputData === 'object') {
        content2 = JSON.stringify(inputData);
      }
      const response = await fetch('http://localhost:1234/v1/chat/completions', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          model: 'lmstudio-community/Meta-Llama-3.1-8B-Instruct-GGUF/Meta-Llama-3.1-8B-Instruct-Q4_K_M.gguf',
          messages: [
            { role: 'system', content: roleContent }, // User-defined system role
            { role: 'user', content: content2 }, // Data from previous node
          ],
          temperature: 0.7,
          max_tokens: -1,
          stream: false,
        }),
      });
  
      const result = await response.json();
      const content = result.choices[0]?.message?.content || 'No response';
  
      // Update the node's data without affecting variables and fieldValues
      setNodes((nds) =>
        nds.map((n) => {
          if (n.id === nodeId) {
            return {
              ...n,
              data: {
                ...n.data,
                apiResult: content,  // Update apiResult with the API response
                // Preserve existing variables and fieldValues if they exist
                variables: n.data.variables ? { ...n.data.variables } : {},
                fieldValues: n.data.fieldValues ? { ...n.data.fieldValues } : {},
              },
            };
          }
          return n;
        })
      );
      
      return content;
    } catch (error) {
      console.error('Error calling API:', error);
      return 'Error';
    }
  };
  

  // Event handlers
  const onConnect = (params) => {
    console.log('Connecting nodes:', params);
    setEdges((eds) => addEdge(params, eds));
  };

  const onDoubleClickEdge = (event, edge) => {
    console.log('Removing edge:', edge);
    setEdges((eds) => eds.filter((e) => e.id !== edge.id)); // Remove edge on double-click
  };

  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
  
      const position = project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
  
      const type = event.dataTransfer.getData('application/reactflow');
      const nodeId = uuidv4();
  
      const NodeComponent = nodeTypes[type];
      let initialData = {
        ...(NodeComponent.defaultData || {}),
        deleteNode: () => handleDeleteNode(nodeId),
      };
      if (type === 'promptEntry') {
        // Ensure the onTextChange function is defined for promptEntry nodes
        initialData = {
          ...initialData,
          onTextChange: (newText) => {
            setNodes((nds) =>
              nds.map((n) => {
                if (n.id === nodeId) {
                  return {
                    ...n,
                    data: {
                      ...n.data,
                      popupText: newText,
                    },
                  };
                }
                return n;
              })
            );
          },
          popupText: '', // Initialize popupText as empty
        };
      }
  
      if (type === 'imageNode') {
        initialData = {
          ...initialData,
          setNodes,
        };
      }
      // **Add handling for combineNode**
  // Add handling for combineNode
  if (type === 'combineNode') {
    initialData = {
      ...initialData,
      combinedData: '', // Initialize combinedData as empty string
      deleteNode: () => handleDeleteNode(nodeId),
    };
  }

  // Add handling for regexNode
  if (type === 'regexNode') {
    initialData.regexCommands = DEFAULT_REGEX_COMMANDS;
    initialData.setRegexCommands = (commands) =>
      setNodes((nds) =>
        nds.map((n) =>
          n.id === nodeId
            ? { ...n, data: { ...n.data, regexCommands: commands } }
            : n
        )
      );
    initialData.processedText = '';
  }

  if (type === 'shapeTextNode') {
    initialData = {
      onTextChange: (newText) => onTextChange(nodeId, newText), // This ensures handleTextChange is properly defined
      deleteNode: () => handleDeleteNode(nodeId),
      selectedShape: 'rectangle',
      nodeText: '',
    };
  }

  if (type.startsWith('customApiNode_')) {
    const config = customApiNodeConfigs[type];
  
    if (config.fetchList && config.fetchList.length > 0) {
      const firstFetchId = config.fetchList[0].id;
  
      // Initialize variables and fieldValues correctly
      const variables = {};
      const variablesForFirstFetch = config.variables[firstFetchId] || [];
      variablesForFirstFetch.forEach((variable) => {
        variables[variable.name] = {
          value: variable.value || '',
          dataType: variable.dataType,
          isEntryPoint: variable.isEntryPoint,
        };
      });
  
      const fieldValues = {};
      const dataFieldsForFetch = config.dataFields[firstFetchId] || [];
      dataFieldsForFetch.forEach((field) => {
        fieldValues[field.name] = field.value !== undefined ? field.value : '';
      });
  
      // Set initialData without calling handleFetchIdChange
      initialData = {
        ...initialData,
        fetchList: config.fetchList,
        selectedFetchId: firstFetchId,
        variables,      // Set initialized variables
        fieldValues,    // Set initialized fieldValues
        config,
        setNodes,
      };
    } else {
      // Handle case when there are no fetch entries
      initialData = {
        ...initialData,
        fetchList: [],
        selectedFetchId: null,
        variables: {},
        fieldValues: {},
        config,
        setNodes,
      };
    }
  }

  const newNode = {
    id: nodeId,
    type: type,
    position,
    data: initialData,
  };

  setNodes((nds) => nds.concat(newNode));
},
[customApiNodeConfigs, nodeTypes, project, setNodes, handleDeleteNode, onTextChange]
);
  
  
  
  
  
  const customNodeFileInputRef = useRef(null);

  const handleImportCustomNode = (event) => {
    const fileReader = new FileReader();
    const file = event.target.files[0];
  
    fileReader.onload = (e) => {
      try {
        const nodeConfig = JSON.parse(e.target.result);
  
        // Validate the nodeConfig as needed
        if (!nodeConfig.nodeName || !Array.isArray(nodeConfig.fetchList)) {
          throw new Error('Invalid custom node configuration.');
        }
  
        // Ensure all necessary fields exist
        nodeConfig.headers = nodeConfig.headers || {};
        nodeConfig.dataFields = nodeConfig.dataFields || {};
        nodeConfig.variables = nodeConfig.variables || {};
        nodeConfig.responsePaths = nodeConfig.responsePaths || {};
        nodeConfig.dataRaw = nodeConfig.dataRaw || {};
  
        // Set the customApiConfig state to the imported configuration
        setCustomApiConfig(nodeConfig);
  
        // Ensure modal is visible
        setIsCustomApiModalVisible(true);
  
        // Reset editing state
        setEditingNodeType(null);
      } catch (error) {
        console.error('Error importing custom node:', error);
        alert('Failed to import the file. Please ensure it is a valid custom node JSON file.');
      }
    };
  
    if (file) {
      fileReader.readAsText(file);
    }
  };
  const [editingNodeType, setEditingNodeType] = useState(null);

  const editCustomNodeType = (nodeTypeName) => {
    const nodeConfig = customApiNodeConfigs[nodeTypeName];
    if (nodeConfig) {
      // Include data fields and variables in the configuration to be set when editing
      setCustomApiConfig({
        ...nodeConfig,
        dataFields: nodeConfig.dataFields || [], // Ensure dataFields are set
        variables: nodeConfig.variables || [],   // Ensure variables are set
      });
      setEditingNodeType(nodeTypeName);
      setIsCustomApiModalVisible(true);
         // Set modalSelectedFetchId to the first fetch entry's ID or the existing one
         setModalSelectedFetchId(nodeConfig.fetchList[0]?.id || null);
        }
  };
  

  
  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  };

  const showModal = () => {
    // Find the starting nodes (nodes with no incoming edges)
    const startingNodes = nodes.filter(
      (node) => getIncomingEdgesLocal(node.id).length === 0
    );
  
    // Find the Prompt Entry Node among the starting nodes
    const promptEntryNode = startingNodes.find(
      (node) => node.type === 'promptEntry'
    );
  
    // Default prompt message
    let promptMessage = 'Enter a prompt';
  
    if (promptEntryNode) {
      // Use the popupText from the Prompt Entry Node
      promptMessage = promptEntryNode.data.popupText || promptMessage;
  
      // Set the promptMessage state
      setPromptMessage(promptMessage);
  
      // Show the modal only if there's a prompt entry node
      setIsModalVisible(true);
    } else {
      // Directly start processing since there are no prompts to ask
      startProcessing();
    }
  };
  const handleOk = async () => {
    console.log('Starting flow with input:', promptValue);
    setIsModalVisible(false);
  
    // Reset node data, including specific logic for 'decisionNode' and 'customApiNode_'
    setNodes((nds) =>
      nds.map((node) => {
        // Reset logic for different node types
        if (node.type === 'decisionNode') {
          return {
            ...node,
            data: {
              ...node.data,
              isWaiting: false,
              executionData: null,
              apiResult: '',
              logContent: '',
              combinedData: '',
              isProcessing: false,
            },
          };
        }
        if (node.type.startsWith('customApiNode_')) {
          return {
            ...node,
            data: {
              ...node.data,
              apiResult: '',
              isProcessing: false,
            },
          };
        }
        return {
          ...node,
          data: {
            ...node.data,
            apiResult: '',
            logContent: '',
            combinedData: '',
            isProcessing: false,
          },
        };
      })
    );
  
    // Initialize node input data
    nodeInputDataRef.current = {};
  
    // Start the flow from the starting nodes (nodes with no incoming edges)
    const startingNodes = nodes.filter((node) => getIncomingEdgesLocal(node.id).length === 0);
  
    for (const node of startingNodes) {
      // Handle different entry node types
      if (node.type === 'promptEntry') {
        nodeInputDataRef.current[node.id] = [promptValue];
      } else if (node.type === 'imageNode') {
        // Assuming `imageInput` is a state where you gather the image URL or input
        const imageInput = node.data.imageUrl || 'defaultImageInput';
        nodeInputDataRef.current[node.id] = [imageInput];
      }
  
      await executeNode({
        nodeId: node.id,
        getNodeById: getNodeByIdLocal,
        getIncomingEdges: getIncomingEdgesLocal,
        getConnectedNodes: getConnectedNodesLocal,
        getOutgoingEdges: getOutgoingEdgesLocal, // **Added this line**
        setNodes,
        nodeInputDataRef,
        promptValue,
        nodeExecutionStateRef,
        handleApiCall,
        executeNode, // Pass the function itself for recursion
      });
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleInputChange = (e) => {
    setPromptValue(e.target.value);
  };
  const handleExport = () => {
    // Collect all custom node configurations
    const customNodeDefinitions = Object.values(customApiNodeConfigs);
  
    // Prepare nodes for export, excluding unnecessary data fields
    const nodesToExport = nodes.map((node) => {
      const { data, ...rest } = node;
      // Exclude 'apiResult', 'isProcessing', and other runtime properties
      const {
        apiResult,
        isProcessing,
        executionData,
        isWaiting,
        decisionMade,
        // Add any other fields you want to exclude
        ...dataWithoutRuntime
      } = data;
      return {
        ...rest,
        data: dataWithoutRuntime,
      };
    });
  
    // Prepare export data
    const flowData = {
      nodes: nodesToExport,
      edges,
      customNodeDefinitions,

    };
    // Convert to JSON and trigger download
    const jsonString = `data:text/json;charset=utf-8,${encodeURIComponent(
      JSON.stringify(flowData, null, 2)
    )}`;
  
    const link = document.createElement('a');
    link.href = jsonString;
    link.download = 'flowData.json';
  
    link.click();
  };
  
  const addCustomNode = (node) => {
    // Extract restOfConfig
    let restOfConfig = node.restOfConfig || {};
  
    // Parse restOfConfig if it's a JSON string
    if (typeof restOfConfig === 'string') {
      restOfConfig = JSON.parse(restOfConfig);
    }
  
    // Build the node configuration
    const config = {
      ...node,
      ...restOfConfig, // Merge restOfConfig into config
      fetchList: restOfConfig.fetchList || [],
      headers: restOfConfig.headers || {},
      dataFields: restOfConfig.dataFields || {},
      variables: restOfConfig.variables || {},
      responsePaths: restOfConfig.responsePaths || {},
      dataRaw: restOfConfig.dataRaw || {},
      market_id: node.id, // Assuming node.id is the market_id
    };
  
    // Register the custom node type
    registerOrUpdateCustomNodeType(config);
  };
  const fileInputRef = useRef(null);

  const handleLoad = (event) => {
    const fileReader = new FileReader();
    const file = event.target.files[0];
  
    fileReader.onload = (e) => {
      try {
        const flowData = JSON.parse(e.target.result);
        const { nodes: loadedNodes, edges: loadedEdges, customNodeDefinitions } = flowData;
  
        // Register all custom node definitions
        if (customNodeDefinitions && customNodeDefinitions.length > 0) {
          customNodeDefinitions.forEach((config) => {
            registerOrUpdateCustomNodeType(config);
          });
        }
  
        // Update node IDs and restore functions
        const updatedNodes = loadedNodes.map((node) => {
          const updatedNode = {
            ...node,
            id: node.id.toString(),
            data: {
              ...node.data,
              deleteNode: () => handleDeleteNode(node.id),
            },
          };
        
            // For Prompt Entry Nodes, add onTextChange function
            if (node.type === 'promptEntry') {
              updatedNode.data.onTextChange = (newText) => onTextChange(node.id, newText);
            }
    
            // For nodes that require setNodes
            if (node.type === 'imageNode' || node.type === 'decisionNode') {
              updatedNode.data.setNodes = setNodes;
            }
    
            // For custom API nodes, ensure data.config is set
            if (node.type.startsWith('customApiNode_')) {
              const config = customApiNodeConfigs[node.type];
              if (config) {
                updatedNode.data.config = config;
              }
            }
    
            // **Ensure RegexNode Receives setRegexCommands and Serialized Commands**
            if (node.type === 'regexNode') {
              // Assign setRegexCommands function
              updatedNode.data.setRegexCommands = (commands) =>
                setNodes((nds) =>
                  nds.map((n) =>
                    n.id === node.id
                      ? { ...n, data: { ...n.data, regexCommands: commands } }
                      : n
                  )
                );
          
             // If regexCommands are missing, initialize with defaults
             if (!updatedNode.data.regexCommands || updatedNode.data.regexCommands.length === 0) {
              updatedNode.data.regexCommands = DEFAULT_REGEX_COMMANDS;
            } else {
              // Serialize any object commands to strings if necessary
              updatedNode.data.regexCommands = updatedNode.data.regexCommands.map((cmd) => {
                if (typeof cmd === 'object' && !Array.isArray(cmd)) {
                  return { ...cmd };
                }
                return cmd;
              });
            }
  
            // Initialize processedText if not present
            if (!updatedNode.data.processedText) {
              updatedNode.data.processedText = '';
            }
          }
  
          return updatedNode;
        });
  
       // Update edges
       const updatedEdges = loadedEdges.map((edge) => ({
        ...edge,
        id: edge.id.toString(),
        source: edge.source.toString(),
        target: edge.target.toString(),
      }));

      setNodes(updatedNodes);
      setEdges(updatedEdges);
    } catch (error) {
      console.error('Error loading flow data:', error);
      alert('Failed to load the file. Please ensure it is a valid flow data JSON file.');
    }
  };

  if (file) {
    fileReader.readAsText(file);
  }
};
  
  
  const handleDeleteFetchEntry = () => {
    const fetchIdToDelete = modalSelectedFetchId;
    const newFetchList = customApiConfig.fetchList.filter(
      (entry) => entry.id !== fetchIdToDelete
    );
  
    // Remove associated data
    const { [fetchIdToDelete]: _, ...newVariables } = customApiConfig.variables;
    const { [fetchIdToDelete]: __, ...newDataFields } = customApiConfig.dataFields;
    const { [fetchIdToDelete]: ___, ...newHeaders } = customApiConfig.headers;
    const { [fetchIdToDelete]: ____, ...newResponsePaths } = customApiConfig.responsePaths;
    const { [fetchIdToDelete]: _____, ...newDataRaw } = customApiConfig.dataRaw;
  
    // Update the customApiConfig
    setCustomApiConfig({
      ...customApiConfig,
      fetchList: newFetchList,
      variables: newVariables,
      dataFields: newDataFields,
      headers: newHeaders,
      responsePaths: newResponsePaths,
      dataRaw: newDataRaw,
    });
  
    // Update modalSelectedFetchId
    if (newFetchList.length > 0) {
      setModalSelectedFetchId(newFetchList[0].id);
    } else {
      setModalSelectedFetchId(null);
    }
  };
  
  

  const handleExportCustomNode = () => {
    // Prepare the custom node configuration
    const nodeConfig = customApiConfig;
  
    // Convert the configuration to JSON
    const jsonString = `data:text/json;charset=utf-8,${encodeURIComponent(
      JSON.stringify(nodeConfig, null, 2)
    )}`;
  
    // Create a download link and click it
    const link = document.createElement('a');
    link.href = jsonString;
    link.download = `${nodeConfig.nodeName || 'custom_node'}.json`;
  
    link.click();
  };
  const [customNodesList, setCustomNodesList] = useState([]);
  const deleteCustomNodeType = async (nodeTypeName) => {
    const config = customApiNodeConfigs[nodeTypeName];
    if (!config) {
      console.error(`Configuration for node type ${nodeTypeName} not found.`);
      return;
    }
  
    const marketId = config.market_id;
  
    if (!marketId || !boardId) {
      console.error('market_id or board_id is missing.');
      return;
    }
  
    // Debugging statements
    console.log('Deleting custom node:', { marketId, boardId, nodeTypeName });
  
    try {
      // Construct the payload with both IDs
      const payload = {
        market_id: marketId,
        board_id: boardId,
      };
  
      const response = await axios.post(
        'https://nodecodestudio.com/backend/unlink_marketplace_node.php',
        payload,
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
  
      if (response.data && response.data.message) {
        message.success(response.data.message);
      } else {
        message.success('Custom node unlinked successfully.');
      }
  
      // Remove the node type from the frontend state
      setCustomApiNodeTypes((prevTypes) => {
        const newTypes = { ...prevTypes };
        delete newTypes[nodeTypeName];
        return newTypes;
      });
  
      // Remove from customNodesList
      setCustomNodesList((prevList) => prevList.filter((node) => node.type !== nodeTypeName));
  
      // Remove the config
      setCustomApiNodeConfigs((prevConfigs) => {
        const newConfigs = { ...prevConfigs };
        delete newConfigs[nodeTypeName];
        return newConfigs;
      });
  
      // Remove any nodes of this type from the canvas
      setNodes((nds) => nds.filter((node) => node.type !== nodeTypeName));
  
      // Remove any edges connected to the deleted nodes
      setEdges((eds) =>
        eds.filter((edge) => {
          const sourceNode = getNodeByIdLocal(edge.source);
          const targetNode = getNodeByIdLocal(edge.target);
          return sourceNode.type !== nodeTypeName && targetNode.type !== nodeTypeName;
        })
      );
    } catch (error) {
      console.error('Error unlinking custom node:', error);
      message.error('Failed to unlink custom node. Please try again.');
    }
  };
  return (
    <div style={{ display: 'flex' }}>
          <Sidebar
        onDragStart={onDragStart}
        showModal={showModal}
        handleExport={handleExport}
        handleLoad={handleLoad}
        userId={userId}
        fileInputRef={fileInputRef}
        showCustomApiModal={showCustomApiModal}
        customNodesList={customNodesList}
        editCustomNodeType={editCustomNodeType}
        deleteCustomNodeType={deleteCustomNodeType}
        boardId ={boardId}
        userAccess={userAccess}
        showMarketplace={() => setIsMarketplaceVisible(true)}

      />
<Modal
  title="Marketplace"
  visible={isMarketplaceVisible}
  onCancel={() => setIsMarketplaceVisible(false)}
  footer={null}
  width={800} // Adjust width as needed
>
  <Marketplace boardId={boardId} userId={userId} addCustomNode={addCustomNode} />
</Modal>
   {/* Custom API Modal */}
   <Modal
  visible={isCustomApiModalVisible}
  onOk={handleCustomApiOk}
  onCancel={handleCustomApiCancel}
  width={600}
  footer={[
    <Button key="import" onClick={() => customNodeFileInputRef.current.click()}>
      Import
    </Button>,
    <Button key="export" onClick={handleExportCustomNode}>
      Export
    </Button>,
    <Button key="cancel" onClick={handleCustomApiCancel}>
      Cancel
    </Button>,
    <Button key="submit" type="primary" onClick={handleCustomApiOk} disabled={!isValidForm}>
      {editingNodeType ? "Update" : "Create"}
    </Button>,
  ]}
>
  <Form layout="vertical">
<h1 >{customApiConfig.nodeName}</h1>
    {userAccess === 'advanced' && (
      <>
    <Form.Item label="Category">
  <Input
    value={customApiConfig.category}
    onChange={(e) =>
      setCustomApiConfig({ ...customApiConfig, category: e.target.value })
    }
    placeholder="e.g., My Custom Nodes"
  />
</Form.Item>
<Form.Item label="Icon">
  <Button onClick={() => setIsIconSelectorVisible(true)}>
    {customApiConfig.iconName ? customApiConfig.iconName : 'Select Icon'}
  </Button>
</Form.Item>
{isIconSelectorVisible && (
  
  <Modal
    title="Select Icon"
    open={isIconSelectorVisible}
    onCancel={() => setIsIconSelectorVisible(false)}
    footer={null}
    width={600} // Adjust width as needed
  >
<IconSelector
  onSelect={(iconName) => {
    setCustomApiConfig({ ...customApiConfig, iconName });
    
  }}
  onClose={() => setIsIconSelectorVisible(false)}
/>

  </Modal>
)}</>)}
    <Form.Item
      label="API Key"
      rules={[{ required: false, message: 'Please enter the API key!' }]}
    >
        <Input
      value={customApiConfig.apiKey}
      onChange={(e) =>
        setCustomApiConfig({ ...customApiConfig, apiKey: e.target.value })
      }
      placeholder="Enter your API key"
    />
    </Form.Item>
  {/* Toggle Instructions Button */}
  {customApiConfig.instructions && (
        <Form.Item>
          <Button type="default" onClick={toggleInstructions}>
            {showInstructions ? 'Hide Instructions' : 'Show Instructions'}
          </Button>
        </Form.Item>
      )}

      {/* Conditionally Render Instructions */}
      {showInstructions && (
        <div
          style={{ marginTop: '10px', }}
          dangerouslySetInnerHTML={{ __html: customApiConfig.instructions }}
        />
      )}

  {/* Add Fetch Entry */}
  {userAccess === 'advanced' && ( <>
  <Form.Item label="Add Fetch Entry">
    <Input
      value={newFetchEntryName}
      onChange={(e) => setNewFetchEntryName(e.target.value)}
      placeholder="Enter Fetch Entry Name"
    />
    <Button onClick={handleAddFetchEntry} style={{ marginTop: '8px' }}>
      Add Fetch Entry
    </Button>
  </Form.Item></>)}
   {/* Select Fetch Entry to Configure */}
   {customApiConfig.fetchList.length > 0 && (
    <Form.Item label="Select Fetch Entry to Configure">
      <Select
        value={modalSelectedFetchId}
        onChange={(value) => setModalSelectedFetchId(value)}
      >
        {customApiConfig.fetchList.map((entry) => (
          <Select.Option key={entry.id} value={entry.id}>
            {entry.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )}
     
    {modalSelectedFetchId && (
    <>   {userAccess === 'advanced' && ( <>
        <Form.Item>
      <Button type="danger" onClick={handleDeleteFetchEntry}>
        Delete Fetch Entry
      </Button>
    </Form.Item>
      <Form.Item label="Fetch Entry Name">
        <Input
          value={
            customApiConfig.fetchList.find(
              (entry) => entry.id === modalSelectedFetchId
            )?.name || ''
          }
          onChange={(e) => {
            const newFetchList = customApiConfig.fetchList.map((entry) =>
              entry.id === modalSelectedFetchId
                ? { ...entry, name: e.target.value }
                : entry
            );
            setCustomApiConfig({ ...customApiConfig, fetchList: newFetchList });
          }}
        />
      </Form.Item>

      <Form.Item label="Request Method">
        <Select
          value={
            customApiConfig.fetchList.find(
              (entry) => entry.id === modalSelectedFetchId
            )?.method || 'GET'
          }
          onChange={(value) => {
            const newFetchList = customApiConfig.fetchList.map((entry) =>
              entry.id === modalSelectedFetchId
                ? { ...entry, method: value }
                : entry
            );
            setCustomApiConfig({ ...customApiConfig, fetchList: newFetchList });
          }}
        >
          {['GET', 'POST', 'PUT', 'PATCH', 'DELETE'].map((method) => (
            <Select.Option key={method} value={method}>
              {method}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Fetch URL">
        <Input
          value={
            customApiConfig.fetchList.find(
              (entry) => entry.id === modalSelectedFetchId
            )?.fetchUrl || ''
          }
          onChange={(e) => {
            const newFetchList = customApiConfig.fetchList.map((entry) =>
              entry.id === modalSelectedFetchId
                ? { ...entry, fetchUrl: e.target.value }
                : entry
            );
            setCustomApiConfig({ ...customApiConfig, fetchList: newFetchList });
          }}
        />
      </Form.Item>
   {/* Headers Table */}
   <Form.Item label="Headers">
        <HeadersTable
          headers={customApiConfig.headers[modalSelectedFetchId] || []}
          setHeaders={(headers) =>
            setCustomApiConfig({
              ...customApiConfig,
              headers: {
                ...customApiConfig.headers,
                [modalSelectedFetchId]: headers,
              },
            })
          }
        />
      </Form.Item>


    {/* Data Fields Table */}
    <Form.Item label="Data Fields">
        <DataFieldsTable
          dataFields={customApiConfig.dataFields[modalSelectedFetchId] || []}
          setDataFields={(dataFields) =>
            setCustomApiConfig({
              ...customApiConfig,
              dataFields: {
                ...customApiConfig.dataFields,
                [modalSelectedFetchId]: dataFields,
              },
            })
          }
        />
      </Form.Item>


      {/* Variables Table */}
      <Form.Item label="Variables">
        <VariablesTable
          variables={customApiConfig.variables[modalSelectedFetchId] || []}
          setVariables={(variables) =>
            setCustomApiConfig({
              ...customApiConfig,
              variables: {
                ...customApiConfig.variables,
                [modalSelectedFetchId]: variables,
              },
            })
          }
        />
      </Form.Item></>)}

          {/* Response Paths Table */}
      <Form.Item label="Response Extraction Paths">
        <ResponsePathsTable
          responsePaths={customApiConfig.responsePaths[modalSelectedFetchId] || []}
          setResponsePaths={(paths) =>
            setCustomApiConfig({
              ...customApiConfig,
              responsePaths: {
                ...customApiConfig.responsePaths,
                [modalSelectedFetchId]: paths,
              },
            })
          }
        />
      </Form.Item>


      {/* Data Raw Payload */}
      {['POST', 'PUT', 'PATCH', 'DELETE'].includes(
        customApiConfig.fetchList.find(
          (entry) => entry.id === modalSelectedFetchId
        )?.method || ''
      ) && (
        <Form.Item label="Data Raw Payload (JSON)">
          <Input.TextArea
            value={customApiConfig.dataRaw[modalSelectedFetchId] || ''}
            onChange={(e) =>
              setCustomApiConfig({
                ...customApiConfig,
                dataRaw: {
                  ...customApiConfig.dataRaw,
                  [modalSelectedFetchId]: e.target.value,
                },
              })
            }
            placeholder='e.g., {"key":"value"}'
          />
        </Form.Item>
      )}
    </>
  )}
</Form>
  <input
    type="file"
    accept=".json"
    ref={customNodeFileInputRef}
    style={{ display: 'none' }}
    onChange={handleImportCustomNode}
  />
</Modal>

      <div
        className="reactflow-wrapper"
        ref={reactFlowWrapper}
        onDrop={onDrop}
        onDragOver={onDragOver}
        style={{ width: '80%', height: 'calc(100vh - 50px' }}
      >
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onEdgeDoubleClick={onDoubleClickEdge}
          fitView
        >
          <MiniMap />
          <Controls />
          <Background color="#aaa" gap={16} />
        </ReactFlow>
      </div>

      <Modal
  title={promptMessage} // Use the promptMessage state
  open={isModalVisible}
  onOk={handleOk}
  onCancel={handleCancel}
>
<Input value={promptValue} onChange={handleInputChange} />
</Modal>
    </div>
  );
};

// Ensure the export is at the top level
export default function AppWrapper() {
  return (
    <ReactFlowProvider>
      <NodeCreator />
    </ReactFlowProvider>
  );
}