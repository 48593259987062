// IconSelector.jsx
import React, { useState } from 'react';
import * as Icons from 'react-icons/bs';
import { Input, List,  } from 'antd';
import './IconSelector.css'; // Ensure this CSS file includes the styles below

const IconSelector = ({ onSelect, onClose }) => {
  const [searchTerm, setSearchTerm] = useState('');

  // Filter icons based on the search term
  const iconKeys = Object.keys(Icons).filter((key) =>
    key.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="icon-selector-container" onClick={(e) => e.stopPropagation()}>


      {/* Search Input */}
      <Input
        placeholder="Search Icons"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="icon-selector-search"
      />

      {/* Icon Grid using Ant Design's List with Grid Layout */}
      <List
        grid={{ gutter: 16, column: 5 }}
        dataSource={iconKeys}
        renderItem={(iconKey) => {
          const IconComponent = Icons[iconKey];
          if (!IconComponent) return null; // Safeguard against undefined icons

          return (
            <List.Item>
              <div
                className="icon-container"
                onClick={() => {
                  onSelect(iconKey);
                  onClose();
                }}
                role="button"
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    onSelect(iconKey);
                    onClose();
                  }
                }}
              >
                <IconComponent size={32} />
                <div className="icon-label">{iconKey}</div>
              </div>
            </List.Item>
          );
        }}
        className="icon-list"
      />
    </div>
  );
};

export default IconSelector;