import jsonpath from 'jsonpath';
function ensureString(data) {
  if (typeof data === 'string') {
    return data;
  } else if (typeof data === 'object') {
    return JSON.stringify(data, null, 2);
  } else {
    return String(data);
  }
}
const replaceVariables = (str, variables) => {
  return str.replace(/\{([^}]+)\}/g, (match, p1) => {
    return variables[p1] !== undefined ? variables[p1] : match;
  });
};
// Helper function to set nested values in an object based on dot notation
function setNestedValue(obj, path, value) {
  const keys = path.replace(/\[(\w+)\]/g, '.$1').split('.');
  let current = obj;
  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i];
    if (!(key in current)) {
      // Determine if next key is an array index
      if (keys[i + 1] && keys[i + 1].match(/^\d+$/)) {
        current[key] = [];
      } else {
        current[key] = {};
      }
    }
    current = current[key];
  }
  current[keys[keys.length - 1]] = value;
}
// Move resetExecutionState outside of executeNode
export const resetExecutionState = (setNodes, nodeInputDataRef) => {
  // Reset the isExecuted and isProcessing flags for all nodes
  setNodes((nds) =>
    nds.map((n) => ({
      ...n,
      data: {
        ...n.data,
        isProcessing: false,
        isExecuted: false,
        // Reset other node-specific states if necessary
        isWaiting: false,
        executionData: null,
      },
    }))
  );

  // Clear input data references
  nodeInputDataRef.current = {};
};

export const executeNode = async ({
  nodeId,
  getNodeById,
  getIncomingEdges,
  getConnectedNodes,
  getOutgoingEdges, // Ensure this parameter is included
  setNodes,
  nodeInputDataRef,
  nodeExecutionStateRef,
  promptValue,
  handleApiCall,
  executeNode,
}) => {
  const node = getNodeById(nodeId);
  console.log(`\n---\nStarting execution of node ${nodeId} of type ${node.type}`);

  // Initialize node execution state if not present
  if (!nodeExecutionStateRef.current[nodeId]) {
    nodeExecutionStateRef.current[nodeId] = {
      isProcessing: false,
      isExecuted: false,
    };
  }




// Build a map of incoming edges per handle ID
const incomingEdges = getIncomingEdges(nodeId);
const incomingEdgesPerHandle = {};
incomingEdges.forEach((edge) => {
  if (edge.targetHandle) {
    incomingEdgesPerHandle[edge.targetHandle] = true;
  }
});


  // Get the list of expected input handles (entry points with incoming edges)
  const expectedInputHandles = Object.keys(incomingEdgesPerHandle);

  // Check if all expected inputs are received before setting isProcessing
  const nodeInputData = nodeInputDataRef.current[nodeId] || {};
  const receivedInputHandles = Object.keys(nodeInputData);

  const allInputsReceived = expectedInputHandles.every((handleId) =>
    receivedInputHandles.includes(handleId)
  );

  if (expectedInputHandles.length > 0 && !allInputsReceived) {
    console.log(
      `Node ${nodeId} is waiting for all inputs. Received ${
        receivedInputHandles.length
      } of ${expectedInputHandles.length}`
    );
    // Inputs are not yet complete, return and wait
    return;
  }

  // Set isProcessing to true now that all inputs are ready
  nodeExecutionStateRef.current[nodeId].isProcessing = true;

  // Update node state to reflect processing
  setNodes((nds) =>
    nds.map((n) => {
      if (n.id === nodeId) {
        return {
          ...n,
          data: {
            ...n.data,
            isProcessing: true,
          },
        };
      }
      return n;
    })
  );


  let inputData = nodeInputDataRef.current[nodeId] || [];
  console.log(`Node ${nodeId} received input data:`, inputData);

  let outputData = null;

  // Process node based on its type
  if (node.type === 'promptEntry') {
    outputData = promptValue;
    console.log(`Node ${nodeId} prompt value:`, outputData);
  } else if (node.type === 'decisionNode') {
    outputData = inputData[0];
    console.log(`Node ${nodeId} received data:`, outputData);

    await new Promise((resolve) => {
      // Update the node's data to indicate waiting state and provide a resume function
      setNodes((nds) =>
        nds.map((n) => {
          if (n.id === nodeId) {
            return {
              ...n,
              data: {
                ...n.data,
                isWaiting: true,
                executionData: outputData,
                resumeExecution: (decision, updatedData) => {
                  // Continue execution based on user's decision
                  setNodes((nds) =>
                    nds.map((n) => {
                      if (n.id === nodeId) {
                        return {
                          ...n,
                          data: {
                            ...n.data,
                            isWaiting: false,
                            executionData: updatedData,
                          },
                        };
                      }
                      return n;
                    })
                  );

                  // Proceed to the next node(s) based on decision
                  const connectedNodeIds = getConnectedNodes(nodeId, decision);

                  executeNextNodes(connectedNodeIds, updatedData);
                  resolve();
                },
              },
            };
          }
          return n;
        })
      );

      // Function to execute connected nodes based on decision
      const executeNextNodes = async (nextNodeIds, dataToPass) => {
        for (const nextNodeId of nextNodeIds) {
          console.log(`Node ${nodeId} passing data to node ${nextNodeId}`);
          const stringData = ensureString(dataToPass);

          // Collect input data for next node
          if (!nodeInputDataRef.current[nextNodeId]) {
            nodeInputDataRef.current[nextNodeId] = [];
          }
          nodeInputDataRef.current[nextNodeId].push(stringData);

          // Recursively execute the next node
          await executeNode({
            nodeId: nextNodeId,
            getNodeById,
            getIncomingEdges,
            getConnectedNodes,
            setNodes,
            nodeInputDataRef,
            promptValue,
            handleApiCall,
            executeNode, // Pass the function itself for recursion
          });
        }
      };
    });

    // Mark node as executed
    setNodes((nds) =>
      nds.map((n) => {
        if (n.id === nodeId) {
          return {
            ...n,
            data: {
              ...n.data,
              isProcessing: false,
              isExecuted: true,
            },
          };
        }
        return n;
      })
    );

    // Clear the input data for the node to prevent re-processing
    delete nodeInputDataRef.current[nodeId];

    // Return to prevent further execution
    return;
    
  } 
  

  
  
  else if (node.type === 'combineNode') {
    // Combine node waits for all input data
    const combinedObject = {};

    inputData.forEach((data, index) => {
      combinedObject[`input${index + 1}`] = data;
    });

    console.log(`Node ${nodeId} combined data:`, combinedObject);

    // Stringify the combined data for display and output
    outputData = JSON.stringify(combinedObject, null, 2);

    // Update node data for display purposes
    setNodes((nds) =>
      nds.map((n) => {
        if (n.id === nodeId) {
          return {
            ...n,
            data: {
              ...n.data,
              combinedData: outputData,
            },
          };
        }
        return n;
      })
    );
    console.log(`Node ${nodeId} combinedData updated in node data.`);
} 




else if (node.type === 'log') {
    outputData = inputData[0]; // Log node passes the first input
    console.log(`Node ${nodeId} logging data:`, outputData);

    // Update the node's logContent
    setNodes((nds) =>
      nds.map((n) => {
        if (n.id === nodeId) {
          return {
            ...n,
            data: {
              ...n.data,
              logContent: outputData,
            },
          };
        }
        return n;
      })
    );
  } else if (node.type === 'imageNode') {
    outputData = node.data.imageData;
    console.log(`Node ${nodeId} outputting image data:`, outputData);
  } else if (node.type === 'splitNode') {
    outputData = inputData[0]; // Pass through
    console.log(`Node ${nodeId} splitting data:`, outputData);
   } else if (node.type.startsWith('customApiNode_')) {
    const config = node.data.config;
    const selectedFetchId = node.data.selectedFetchId;

    if (!selectedFetchId) {
      console.error('No fetch entry selected for this node.');
      return;
    }

    const fetchEntry = node.data.fetchList.find(
      (fetch) => fetch.id === selectedFetchId
    );

    if (!fetchEntry) {
      console.error(`Fetch entry with ID '${selectedFetchId}' not found.`);
      return;
    }
  
    // **1. Prepare Variables for Substitution**
    const variables = {};
  
    // Extract and process variables
    const variablesForFetch = config.variables[selectedFetchId] || [];
    variablesForFetch.forEach((variable) => {
      let value;
  
      const handleId = `input-${variable.name}`;
      if (variable.isEntryPoint && nodeInputData[handleId]) {
        value = nodeInputData[handleId]; // Use input value from connected node
        console.log(`Received input for variable ${variable.name}:`, value);
    
        // If the value is an object, serialize it
        if (typeof value === 'object') {
          value = JSON.stringify(value);
          console.log(`Serialized object for variable ${variable.name}:`, value);
        }
      } else {
        const varData = node.data.variables
          ? node.data.variables[variable.name]
          : undefined;
        if (!varData) {
          console.warn(
            `Variable '${variable.name}' is not defined in node.data.variables. Initializing with default value.`
          );
          value =
            variable.defaultValue !== undefined ? variable.defaultValue : ''; // Fallback to default value or empty string
        } else {
          value = varData.value;
          console.log(`Using value for variable ${variable.name}:`, value);
        }
      }
      // Convert value based on the data type
      switch (variable.dataType) {
        case 'number':
          value = Number(value);
          break;
        case 'boolean':
          value = value === 'true' || value === true;
          break;
        case 'object':
        case 'array':
          try {
            if (typeof value === 'string' && value.trim() === '') {
              value = variable.dataType === 'object' ? {} : [];
            } else {
              value = JSON.parse(value);
            }
          } catch (e) {
            console.error(
              `Error parsing ${variable.dataType} for variable ${variable.name}:`,
              e
            );
            value = variable.dataType === 'object' ? {} : [];
          }
          break;
        default:
          value = String(value);
          break;
      }
    
      console.log(`Variable ${variable.name} set to:`, value);
      variables[variable.name] = value; // Store in variables object
    });
  
// Include apiKey in variables if provided
if (config.apiKey) {
  variables['apiKey'] = config.apiKey;
  console.log(`Variable 'apiKey' set to:`, variables['apiKey']);
}

// **Include any additional global variables here**
if (node.data.variables && node.data.variables['Header']) {
  variables['Header'] = node.data.variables['Header'].value;
  console.log(`Variable 'Header' set to:`, variables['Header']);
}

console.log('Variables for substitution:', variables);
  
    // **Declare dataPayload as an empty object here**
    const dataPayload = {};
  
    // **2. Extract and Process Data Fields**
    const dataFieldsForFetch = config.dataFields[selectedFetchId] || [];
    if (dataFieldsForFetch.length > 0) {
      dataFieldsForFetch.forEach((field) => {
        let value;
  
const handleId = `input-${field.name}`;
if (field.isEntryPoint && nodeInputData[handleId]) {
  value = nodeInputData[handleId]; // Use input value from connected node
          console.log(`Injected input value for field ${field.name}:`, value);
        } else {
          value = node.data.fieldValues
            ? node.data.fieldValues[field.name]
            : undefined;
          if (value === undefined) {
            value = field.value !== undefined ? field.value : ''; // Use default value
          }
          console.log(`Using value for field ${field.name}:`, value);
        }
  
        // Define recursiveReplace function
        function recursiveReplace(obj, variables) {
          if (typeof obj === 'string') {
            return replaceVariables(obj, variables);
          } else if (Array.isArray(obj)) {
            return obj.map((item) => recursiveReplace(item, variables));
          } else if (typeof obj === 'object' && obj !== null) {
            const newObj = {};
            for (const key in obj) {
              newObj[key] = recursiveReplace(obj[key], variables);
            }
            return newObj;
          } else {
            return obj;
          }
        }
  
        // Convert value based on the data type
        switch (field.dataType) {
          case 'number':
            value = Number(value);
            break;
          case 'boolean':
            value = value === 'true' || value === true;
            break;
          case 'object':
          case 'array':
            try {
              if (typeof value === 'string' && value.trim() === '') {
                value = field.dataType === 'object' ? {} : []; // Empty object or array
              } else {
                // Parse the JSON string
                value = JSON.parse(value);
              }
              // Perform recursive variable substitution
              value = recursiveReplace(value, variables);
            } catch (e) {
              console.error(
                `Error parsing ${field.dataType} for field ${field.name}:`,
                e
              );
              value = field.dataType === 'object' ? {} : [];
            }
            break;
          default:
            // For strings and other types, perform variable substitution directly
            value = replaceVariables(String(value), variables);
            break;
        }
  
        console.log(`Field ${field.name} set to:`, value);
        setNestedValue(dataPayload, field.name, value);
      });
    }
  
    // **3. Substitute Variables in fetchUrl**
    let fetchUrl = fetchEntry.fetchUrl;
    // Replace placeholders like {variableName} with actual values from variables
    fetchUrl = fetchUrl.replace(/{(\w+)}/g, (match, p1) => {
      const replacement = variables[p1];
      if (replacement === undefined || replacement === '') {
        console.warn(
          `No value provided for variable '${p1}' in fetch URL. Using fallback.`
        );
        return '0'; // Provide a fallback value such as '0' for missing values
      }
      return encodeURIComponent(replacement);
    });
  
    console.log('Processed Fetch URL:', fetchUrl);
  
    // **4. Prepare Data Raw Payload (if any)**
    let dataRaw = null;
    if (
      config.dataRaw &&
      config.dataRaw[selectedFetchId] &&
      config.dataRaw[selectedFetchId].trim() !== '' &&
      ['POST', 'PUT', 'PATCH', 'DELETE'].includes(fetchEntry.method.toUpperCase())
    ) {
      try {
        // Retrieve the Data Raw Payload string
        let dataRawString = config.dataRaw[selectedFetchId];
  
        // Perform variable substitution using variables object
        dataRawString = dataRawString.replace(/{(\w+)}/g, (match, p1) => {
          let replacement = variables[p1];
          if (replacement === undefined || replacement === '') {
            console.warn(
              `No value provided for variable '${p1}' in Data Raw Payload. Using empty string as fallback.`
            );
            return '""'; // Return empty string enclosed in quotes
          }
  
          if (typeof replacement === 'string') {
            // Escape the string and wrap in quotes
            return JSON.stringify(replacement);
          } else {
            // For non-string values, insert the JSON representation
            return JSON.stringify(replacement);
          }
        });
  
        console.log('Data Raw String after substitution:', dataRawString);
  
        // Now parse the resulting string as JSON
        dataRaw = JSON.parse(dataRawString);
        console.log('Parsed dataRaw after substitution:', dataRaw);
      } catch (e) {
        console.error(
          'Invalid JSON in Data Raw Payload after variable substitution:',
          e
        );
        alert(
          'Invalid JSON in Data Raw Payload after variable substitution. Please correct it before running the flow.'
        );
        // Update node to indicate processing end
        setNodes((nds) =>
          nds.map((n) => {
            if (n.id === nodeId) {
              return {
                ...n,
                data: {
                  ...n.data,
                  isProcessing: false,
                  variables: n.data.variables || {},
                  fieldValues: n.data.fieldValues || {},
                },
              };
            }
            return n;
          })
        );
        delete nodeInputDataRef.current[nodeId];
        return; // Abort execution
      }
    }
  
    // **5. Build Headers with Variable Injection**
    const headers = {};
    const headersForFetch = config.headers[selectedFetchId] || [];
    if (headersForFetch.length > 0) {
      headersForFetch.forEach((header) => {
        const headerName = replaceVariables(header.name, variables);
        const headerValue = replaceVariables(header.value, variables);
        headers[headerName] = headerValue;
    
        console.log(`Header '${headerName}' set to:`, headerValue);
      });
    }
  
    // **Automatically set Content-Type if dataRaw is used and not already set**
    if (dataRaw && !headers['Content-Type']) {
      headers['Content-Type'] = 'application/json';
      console.log('Automatically set Content-Type to application/json');
    }
  
    // **6. Make the API Call**
    try {
      console.log('Making API call with:', {
        method: fetchEntry.method ? fetchEntry.method.toUpperCase() : 'GET',
        headers,
        body:
          fetchEntry.method &&
          ['POST', 'PUT', 'PATCH', 'DELETE'].includes(fetchEntry.method.toUpperCase())
            ? dataRaw
              ? JSON.stringify(dataRaw)
              : JSON.stringify(dataPayload)
            : undefined, // Do not include body for GET requests
      });
  
      const fetchOptions = {
        method: fetchEntry.method ? fetchEntry.method.toUpperCase() : 'GET',
        headers,
      };
  
      if (
        fetchEntry.method &&
        ['POST', 'PUT', 'PATCH', 'DELETE'].includes(fetchEntry.method.toUpperCase())
      ) {
        if (dataRaw) {
          fetchOptions.body = JSON.stringify(dataRaw);
        } else {
          fetchOptions.body = JSON.stringify(dataPayload);
        }
      }
  
      const response = await fetch(fetchUrl, fetchOptions);

      // After getting the API response
      let result;
      const contentType = response.headers.get('Content-Type');

      if (contentType && contentType.includes('application/json')) {
        result = await response.json(); // Parse as JSON
      } else if (contentType && contentType.startsWith('image/')) {
        const blob = await response.blob();
        result = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(blob);
        });
      } else {
        result = await response.text();
      }

      // Log the raw API result for debugging purposes
      console.log('API result (raw):', result);

      // **7. Convert the Entire Response to String** (whether paths are specified or not)
      const responsePathsForFetch = (config.responsePaths[selectedFetchId] || []).filter(path => path.active);
  
      if (responsePathsForFetch.length > 0) {
        // Extract only active paths
        const extractedData = {};
        responsePathsForFetch.forEach((pathObj) => {
          try {
            const value = jsonpath.query(result, `$.${pathObj.path}`);
            extractedData[pathObj.name] =
              Array.isArray(value) && value.length === 1 ? value[0] : value;
          } catch (e) {
            console.error(`Error extracting path ${pathObj.path}:`, e);
            extractedData[pathObj.name] = null;
          }
        });
    

        outputData = JSON.stringify(extractedData, null, 2); // Convert to string
      } else {
        // If no paths specified, convert the entire result to a string
        outputData = JSON.stringify(result, null, 2); // Convert the entire response to string
      }

      // Log the stringified data for debugging
      console.log('Output data (stringified):', outputData);

      // **8. Update the Node's Data with the Stringified API Result**
      setNodes((nds) =>
        nds.map((n) => {
          if (n.id === nodeId) {
            return {
              ...n,
              data: {
                ...n.data,
                apiResult: outputData, // Now a string
                variables: n.data.variables || {},
                fieldValues: n.data.fieldValues || {},
              },
            };
          }
          return n;
        })
      );
    } catch (error) {
      console.error('Error calling custom API:', error);
      outputData = 'Error';
    }
  }else if (node.type === 'regexNode') {
    // **RegexNode Processing**
    outputData = inputData[0]; // Assume single input text

    console.log(`Node ${nodeId} processing text:`, outputData);

    const regexCommands = node.data.regexCommands;

    if (!Array.isArray(regexCommands)) {
      console.error(`Node ${nodeId} has invalid regexCommands.`);
      outputData = 'Error: Invalid regex commands.';
    } else {
      // Filter only selected commands
      const selectedCommands = regexCommands.filter((cmd) => cmd.selected);
      if (selectedCommands.length === 0) {
        console.warn(`Node ${nodeId} has no selected regex commands to apply.`);
      } else {
        try {
          // Ensure outputData is a string
          if (typeof outputData !== 'string') {
            console.warn(`Node ${nodeId} received non-string inputData:`, outputData);
            // Attempt to convert to string
            outputData = String(outputData);
          }

          selectedCommands.forEach((cmd) => {
            const { name, pattern, function: func, replacement } = cmd;

            if (!pattern || !func) {
              throw new Error(`Regex command "${name}" is missing pattern or function.`);
            }

            const regex = new RegExp(pattern, 'g');

            if (func === 'replace') {
              if (typeof replacement !== 'string') {
                throw new Error(`Replacement text must be a string for command "${name}".`);
              }
              outputData = outputData.replace(regex, replacement);
              console.log(`Applied replace: /${pattern}/g with "${replacement}"`);
            } else if (func === 'remove') {
              outputData = outputData.replace(regex, '');
              console.log(`Applied remove: /${pattern}/g`);
            } else {
              console.warn(`Unsupported function "${func}" in regex command "${name}".`);
            }
          });

          console.log(`RegexNode ${nodeId} output data after processing:`, outputData);

          // Optionally, you can update the node's data to display the processed text
          setNodes((nds) =>
            nds.map((n) => {
              if (n.id === nodeId) {
                return {
                  ...n,
                  data: {
                    ...n.data,
                    processedText: outputData,
                  },
                };
              }
              return n;
            })
          );
        } catch (error) {
          console.error(`Error processing RegexNode ${nodeId}:`, error);
          outputData = `Error: ${error.message}`;
        }
      }
    }
    outputData = ensureString(outputData);

  } else {
    // Default node processing for other types
    outputData = inputData[0]; // Default to first input if no specific processing logic
    console.log(`Node ${nodeId} default processing:`, outputData);
  }

 // Ensure output data is in string format
outputData = ensureString(outputData);

// Mark the node as processed and update its state
nodeExecutionStateRef.current[nodeId].isProcessing = false;
nodeExecutionStateRef.current[nodeId].isExecuted = true;

setNodes((nds) =>
  nds.map((n) => {
    if (n.id === nodeId) {
      return {
        ...n,
        data: {
          ...n.data,
          isProcessing: false,
          isExecuted: true,
          logContent: outputData,
        },
      };
    }
    return n;
  })
);

// Clear the input data for the node to prevent re-processing
delete nodeInputDataRef.current[nodeId];

// Get outgoing edges from the current node
const outgoingEdges = getOutgoingEdges(nodeId);
console.log(`Node ${nodeId} will now pass data to connected nodes via edges:`, outgoingEdges);

// Process connected nodes sequentially
for (const edge of outgoingEdges) {
  const nextNodeId = edge.target;
  const targetHandleId = edge.targetHandle || 'default'; // Use 'default' if no handle ID

  console.log(`Node ${nodeId} passing data to node ${nextNodeId} via handle ${targetHandleId}`);

  // Collect input data for the next node
  if (!nodeInputDataRef.current[nextNodeId]) {
    nodeInputDataRef.current[nextNodeId] = {};
  }
  nodeInputDataRef.current[nextNodeId][targetHandleId] = outputData;

  // Recursively execute the connected node
  await executeNode({
    nodeId: nextNodeId,
    getNodeById,
    getIncomingEdges,
    getOutgoingEdges, // Ensure this is passed
    getConnectedNodes,
    setNodes,
    nodeInputDataRef,
    nodeExecutionStateRef,
    promptValue,
    handleApiCall,
    executeNode,
  });
}
console.log(`Finished execution of node ${nodeId}`);
};