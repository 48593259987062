import React, { useState, useEffect } from 'react';
import { Handle } from 'react-flow-renderer';
import { Button, Modal, Input } from 'antd';
import {BsQuestionDiamondFill} from 'react-icons/bs';
import './NodeStyling.css';
const DecisionNode = ({ data, id }) => {
  // eslint-disable-next-line
  const { setNodes, executionData, isWaiting } = data;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editedText, setEditedText] = useState(executionData);
  const [decisionMade, setDecisionMade] = useState(false);

  const isImage = (content) => {
    return (
      typeof content === 'string' &&
      content.startsWith('data:image/') // Check if content is a Data URL
    );
  };

  const handleYesClick = () => {
    setDecisionMade(true);
    data.resumeExecution('yes', editedText || executionData);
  };

  const handleNoClick = () => {
    setDecisionMade(true);
    data.resumeExecution('no', editedText || executionData);
  };

  const handleEditClick = () => {
    setIsModalVisible(true);
  };

  const handleModalOk = () => {
    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    // Reset decisionMade and editedText when new executionData is received
    setDecisionMade(false);
    setEditedText(executionData);
  }, [executionData]);

  return (
    <div
      style={{
        padding: '10px',
        border: '1px solid black',
        borderRadius: '4px',
        backgroundColor: '#fffde7',
        position: 'relative',
        width: '200px',
      }}
    >
      {/* Node Title */}
      <div style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '20px', marginTop: '20px' }}>
      <span><BsQuestionDiamondFill  style={{ marginRight: '2px' }} />   Decision Node</span>
      <span className="node-delete"
        onClick={data.deleteNode}
      >
        x
      </span></div>

      {/* Display the Data */}
      {executionData && (
        <div style={{ marginBottom: '10px', whiteSpace: 'pre-wrap' }}>
          {isImage(executionData) ? (
            <img
              src={executionData}
              alt="Data"
              style={{ maxWidth: '100%', maxHeight: '100px' }}
            />
          ) : (
            <div>{executionData}</div>
          )}
        </div>
      )}

      {/* Decision Buttons */}
      {!decisionMade && isWaiting && (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button type="primary" onClick={handleYesClick}>
            Yes
          </Button>
          {!isImage(executionData) && (
            <Button onClick={handleEditClick}>Edit</Button>
          )}
          <Button type="danger" onClick={handleNoClick}>
            No
          </Button>
        </div>
      )}

      {/* Modal for Text Editing */}
      <Modal
        title="Edit Text"
        visible={isModalVisible}
        onOk={() => {
          handleModalOk();
          // Update the edited text state
          setEditedText(editedText);
        }}
        onCancel={handleModalCancel}
      >
        <Input.TextArea
          value={editedText}
          onChange={(e) => setEditedText(e.target.value)}
          rows={4}
        />
      </Modal>

      {/* Input Handle */}
      <Handle
        type="target"
        position="left"
        id="input"
        style={{ top: '50%', transform: 'translateY(-50%)' }}
      />
<div className="decision-yes">Y</div>
      {/* Output Handles */}
      <Handle
        type="source"
        position="right"
        id="yes"
        style={{ top: '30%', transform: 'translateY(-50%)' }}
      />
      <div className="decision-no">N</div>
      <Handle
        type="source"
        position="right"
        id="no"
        style={{ top: '70%', transform: 'translateY(-50%)' }}
      />
    </div>
  );
};

DecisionNode.defaultData = {
  executionData: null,
  isWaiting: false,
  resumeExecution: () => {},
};

export default DecisionNode;
