// src/components/VariablesTable.js

import React from 'react';
import { Table, Button, Input, Checkbox, Select } from 'antd';

const { Option } = Select;

const VariablesTable = ({ variables, setVariables }) => {
  const handleVariableChange = (index, key, value) => {
    const newVariables = [...variables];
    newVariables[index][key] = value;
    setVariables(newVariables);
  };

  const columns = [
    {
      title: 'Variable Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record, index) => (
        <Input
          value={text}
          onChange={(e) => handleVariableChange(index, 'name', e.target.value)}
          placeholder="e.g., query"
        />
      ),
    },
    {
      title: 'Variable Value',
      dataIndex: 'value',
      key: 'value',
      render: (text, record, index) => (
        <Input
          value={text}
          onChange={(e) => handleVariableChange(index, 'value', e.target.value)}
          placeholder="e.g., people"
        />
      ),
    },
    {
      title: 'Data Type',
      dataIndex: 'dataType',
      key: 'dataType',
      render: (text, record, index) => (
        <Select
          value={text}
          onChange={(value) => handleVariableChange(index, 'dataType', value)}
          style={{ width: '100%' }}
        >
          <Option value="string">String</Option>
          <Option value="number">Number</Option>
          <Option value="boolean">Boolean</Option>
          <Option value="object">Object</Option>
          <Option value="array">Array</Option>
        </Select>
      ),
    },
    {
      title: 'Is Entry Point',
      dataIndex: 'isEntryPoint',
      key: 'isEntryPoint',
      render: (text, record, index) => (
        <Checkbox
          checked={text}
          onChange={(e) => handleVariableChange(index, 'isEntryPoint', e.target.checked)}
        />
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record, index) => (
        <Button
          danger
          onClick={() => {
            const newVariables = variables.filter((_, i) => i !== index);
            setVariables(newVariables);
          }}
        >
          Delete
        </Button>
      ),
    },
  ];

  const dataSource = variables.map((variable, index) => ({
    key: index,
    ...variable,
  }));

  return (
    <div>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        rowKey="key"
      />
      <Button
        type="dashed"
        onClick={() =>
          setVariables([...variables, { name: '', value: '', dataType: 'string', isEntryPoint: false }])
        }
        style={{ marginTop: '10px' }}
      >
        Add Variable
      </Button>
    </div>
  );
};

export default VariablesTable;

