import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userId, setUserId] = useState(localStorage.getItem('userId') || null);
  // eslint-disable-next-line
  const [userAccess, setUserAccess] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Simulate fetching the user data
    async function fetchUserData() {
      try {
        // Fetch user data logic here
        const fetchedUserId = "12345"; // Example fetched ID
        
        setUserId(fetchedUserId);
        localStorage.setItem('userId', fetchedUserId); // Save to localStorage
      } catch (err) {
        setError('Failed to fetch user data');
      } finally {
        setLoading(false);
      }
    }

    if (!userId) {
      fetchUserData();
    } else {
      setLoading(false);
    }
  }, [userId]);

  return (
    <AuthContext.Provider value={{ userId, userAccess, loading, error }}>
      {children}
    </AuthContext.Provider>
  );
};