// src/constants/regexCommands.js

export const DEFAULT_REGEX_COMMANDS = [
  {
    key: 'default-remove-whitespace',
    name: 'Remove Whitespace',
    pattern: '\\s+',
    function: 'remove',
    replacement: '',
    isDefault: true,
    selected: false, // Initially not selected
  },
  {
    key: 'default-replace-multiple-spaces',
    name: 'Replace Multiple Spaces',
    pattern: '\\s{2,}',
    function: 'replace',
    replacement: ' ',
    isDefault: true,
    selected: false, // Initially not selected
  },
  {
    key: 'default-remove-digits',
    name: 'Remove Digits',
    pattern: '\\d+',
    function: 'remove',
    replacement: '',
    isDefault: true,
    selected: false, // Initially not selected
  },
];