// src/components/nodes/PromptEntryNode.js

import React from 'react';
import { Handle } from 'react-flow-renderer';
import './NodeStyling.css';
import { BsPencil } from 'react-icons/bs';
import './NodeStyling.css';
const PromptEntryNode = ({ data }) => {
  const handleTextChange = (event) => {
    const newText = event.target.value;
    data.onTextChange(newText); // Use the function passed via data prop
  };

  return (
    <div className="node-border">
       <div className='topBar'> <BsPencil className="nodeIcon" />
        <span className='node-title'>
         Prompt Entry
        </span>
       {data.label}
        <span
          className="node-delete"
          onClick={data.deleteNode}
        >
          x
        </span>
      </div>
      {/* New Text Input Field */}
      <div style={{ marginTop: '10px' }}>
        <label>
          Text to show user:
          <input
            type="text"
            value={data.popupText || ''}
            onChange={handleTextChange}
            style={{ width: '100%', marginTop: '5px' }}
          />
        </label>
      </div>
      <Handle type="source" position="right" id="output" />
    </div>
  );
};

export default PromptEntryNode;