// src/components/nodes/RegexNode.js

import React, { useState, useEffect } from 'react';
import { Handle } from 'react-flow-renderer';
import { DeleteOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';
import { Table, Input, Select, Button, Modal, Checkbox, Tooltip } from 'antd';
import { DEFAULT_REGEX_COMMANDS } from '../../constants/regexCommands'; // Adjust the path as necessary
import { BsTextLeft } from 'react-icons/bs';
const { Option } = Select;

const RegexNode = ({ data, id }) => {
  // eslint-disable-next-line
  const { regexCommands = [], setRegexCommands, deleteNode } = data;

  // Initialize regex commands with defaults if not already present
  useEffect(() => {
    if (regexCommands.length === 0) {
      setRegexCommands(DEFAULT_REGEX_COMMANDS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingKey, setEditingKey] = useState('');

  const isEditing = (record) => record.key === editingKey;

  // Handle Add Command inside modal
  const handleAdd = () => {
    const newCommand = {
      key: Date.now(),
      name: '',
      pattern: '',
      function: 'replace', // default function
      replacement: '',
      isDefault: false,
      selected: true,
    };
    setRegexCommands([...regexCommands, newCommand]);
    setEditingKey(newCommand.key);
  };

  // Handle Delete Command inside modal
  const handleDelete = (key) => {
    setRegexCommands(regexCommands.filter((item) => item.key !== key));
  };

  // Handle Save after editing a command
  const handleSave = (record) => {
    // Validation: Ensure required fields are filled
    if (!record.name || !record.pattern || !record.function) {
      alert('Please fill in all required fields.');
      return;
    }

    if (record.function === 'replace' && !record.replacement) {
      alert('Please provide a replacement text for the replace function.');
      return;
    }

    // Validate regex pattern
    try {
      new RegExp(record.pattern);
    } catch (e) {
      alert(`Invalid regex pattern: ${e.message}`);
      return;
    }

    setRegexCommands(
      regexCommands.map((item) => (item.key === record.key ? record : item))
    );
    setEditingKey('');
  };

  // Handle Checkbox Selection
  const handleSelectChange = (key, checked) => {
    setRegexCommands(
      regexCommands.map((item) =>
        item.key === key ? { ...item, selected: checked } : item
      )
    );
  };

  // Define table columns
  const columns = [
    {
      title: 'Select',
      dataIndex: 'selected',
      width: '5%',
      render: (text, record) => (
        <Checkbox
          checked={record.selected}
          onChange={(e) => handleSelectChange(record.key, e.target.checked)}
        />
      ),
    },
    {
      title: 'Command Name',
      dataIndex: 'name',
      width: '25%',
      editable: true,
      render: (text, record) => {
        if (isEditing(record)) {
          return (
            <Input
              value={text}
              onChange={(e) =>
                setRegexCommands(
                  regexCommands.map((item) =>
                    item.key === record.key
                      ? { ...item, name: e.target.value }
                      : item
                  )
                )
              }
            />
          );
        }
        return text;
      },
    },
    {
      title: 'Pattern',
      dataIndex: 'pattern',
      width: '30%',
      editable: true,
      render: (text, record) => {
        if (isEditing(record)) {
          return (
            <Input
              value={text}
              onChange={(e) =>
                setRegexCommands(
                  regexCommands.map((item) =>
                    item.key === record.key
                      ? { ...item, pattern: e.target.value }
                      : item
                  )
                )
              }
            />
          );
        }
        return `/${text}/g`;
      },
    },
    {
      title: 'Function',
      dataIndex: 'function',
      width: '20%',
      editable: true,
      render: (text, record) => {
        if (isEditing(record)) {
          return (
            <Select
              value={text}
              onChange={(value) =>
                setRegexCommands(
                  regexCommands.map((item) =>
                    item.key === record.key
                      ? { ...item, function: value }
                      : item
                  )
                )
              }
              style={{ width: '100%' }}
            >
              <Option value="replace">Replace</Option>
              <Option value="remove">Remove</Option>
            </Select>
          );
        }
        return text.charAt(0).toUpperCase() + text.slice(1);
      },
    },
    {
      title: 'Replacement',
      dataIndex: 'replacement',
      width: '20%',
      editable: true,
      render: (text, record) => {
        if (record.function === 'replace') {
          if (isEditing(record)) {
            return (
              <Input
                value={text}
                onChange={(e) =>
                  setRegexCommands(
                    regexCommands.map((item) =>
                      item.key === record.key
                        ? { ...item, replacement: e.target.value }
                        : item
                    )
                  )
                }
              />
            );
          }
          return text;
        }
        return 'N/A';
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      width: '10%',
      render: (_, record) =>
        !record.isDefault ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {isEditing(record) ? (
              <Tooltip title="Save">
                <span
                  onClick={() => handleSave(record)}
                  style={{
                    marginRight: 8,
                    color: 'green',
                    cursor: 'pointer',
                  }}
                >
                  Save
                </span>
              </Tooltip>
            ) : (
              <Tooltip title="Edit">
                <EditOutlined
                  onClick={() => setEditingKey(record.key)}
                  style={{ marginRight: 8, color: 'blue', cursor: 'pointer' }}
                />
              </Tooltip>
            )}
            <Tooltip title="Delete">
              <DeleteOutlined
                onClick={() => handleDelete(record.key)}
                style={{ color: 'red', cursor: 'pointer' }}
              />
            </Tooltip>
          </div>
        ) : null,
    },
  ];

  return (
    <div className="node-border">
         <div className='topBar'> <BsTextLeft className="nodeIcon" />
         <span className='node-title'>
          Regex Node
        </span>
      {/* Delete ('x') Button */}
      <span
          className="node-delete"
          onClick={data.deleteNode}
        >
          x
        </span>
      </div>

      {/* Node Title and Edit Button */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '10px',
        }}
      >
    
        <Button
          type="primary"
          size="small"
          icon={<EditOutlined />}
          className="regexButton"
          onClick={() => setIsModalVisible(true)}
        >
          Commands
        </Button>
      </div>

      {/* Display Processed Text */}
      {data.processedText && (
        <div
          style={{
            marginTop: '10px',
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-all',
            borderTop: '1px solid #ddd',
            paddingTop: '10px',
          }}
        >
          <strong>Processed Text:</strong>
          <div>{data.processedText}</div>
        </div>
      )}

      {/* Input and Output Handles */}
      <Handle
        type="target"
        position="left"
        id="input"
        style={{ top: '50%', transform: 'translateY(-50%)' }}
      />
      <Handle
        type="source"
        position="right"
        id="output"
        style={{ top: '50%', transform: 'translateY(-50%)' }}
      />

      {/* Edit Modal */}
      <Modal
        title="Edit Regex Commands"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setIsModalVisible(false)}>
            Close
          </Button>,
          <Button
            key="add"
            type="dashed"
            onClick={handleAdd}
            icon={<PlusOutlined />}
          >
            Add Command
          </Button>,
        ]}
        width={700}
      >
        <Table
          dataSource={regexCommands}
          columns={columns}
          pagination={false}
          rowClassName="editable-row"
          size="middle"
          bordered
          rowKey="key" // Ensure each row has a unique key
        />
      </Modal>
    </div>
  );
};

RegexNode.defaultData = {
  regexCommands: [],
  setRegexCommands: () => {},
  deleteNode: () => {},
  processedText: '',
};

export default RegexNode;