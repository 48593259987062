// src/components/Sidebar.js

import React, { useState } from 'react';
import { Button, Tooltip, Select } from 'antd';
import { EditOutlined, DeleteOutlined,  } from '@ant-design/icons';
import {
  BsImage,
  BsQuestionDiamondFill,
  BsPencil,
  BsGraphUp,
  BsList,
  BsTextLeft,
  BsSticky,
  BsFillSquareFill,
} from 'react-icons/bs'; // Importing Bootstrap Icons


import * as Icons from 'react-icons/bs';
import { useLocation } from 'react-router-dom'; // Import useLocation to get current route
import './sidebar.css';
// eslint-disable-next-line
const { Option } = Select;

const Sidebar = ({
  onDragStart,
  showModal,
  handleExport,
  handleLoad,
  fileInputRef,
  showCustomApiModal,
  customNodesList,
  editCustomNodeType,
  deleteCustomNodeType,
  boardId,
  showMarketplace,
  userId,
  userAccess
}) => {
  const customNodesByCategory = customNodesList.reduce((acc, node) => {
    const category = node.category || 'Uncategorized';
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(node);
    return acc;
  }, {});
  const location = useLocation(); // Get current route
  const isNodeCreator = location.pathname === '/node-creator'; // Check if current route is node-creator

  const [customCategoriesOpen, setCustomCategoriesOpen] = useState({});
  const [isEntryOpen, setEntryOpen] = useState(false);

  const [isNodeFunctionsOpen, setNodeFunctionsOpen] = useState(false);
  const [isLoggingOpen, setLoggingOpen] = useState(false);
  const [isNotesOpen, setNotesOpen] = useState(false); // New state for Notes


  const toggleLogging = () => setLoggingOpen(!isLoggingOpen);
  const toggleNotes = () => setNotesOpen(!isNotesOpen); // Toggle Notes category

  // Toggles for different categories
  const toggleCategory = (setCategoryState) => setCategoryState((prev) => !prev);
  const toggleCustomCategory = (category) => {
    setCustomCategoriesOpen((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };








  return (
    <aside
      style={{
        padding: '10px',
        width: '20%',
        backgroundColor: '#f4f4f4',
        borderRight: '1px solid #ddd',
        height: 'calc(100vh - 50px)',
        overflowY: 'auto',
      }}
    >
      
      <div style={{ marginBottom: '20px' }}>
      <Button

  onClick={showModal} // This will now correctly determine whether to show the modal or start processing directly.
  style={{ width: '100%', marginBottom: '25px' }}
  className="start-btn"
>
  Start
</Button>
        {/* "Save" Button for NodeCreator */}
        {isNodeCreator && (
          <Button
  type="primary"
  onClick={() => {
    // Emit a custom event to trigger save in NodeCreator
    window.dispatchEvent(new Event('saveBoard'));
  }}
  style={{ width: '100%', marginBottom: '10px' }}
>
  Save
</Button>
        )}
    
    {userAccess === 'advanced' && (
      <>
        <Button
          type="default"
          onClick={handleExport}
          style={{ width: '100%', marginBottom: '10px' }}
        >
          Export
        </Button>
        <input
          type="file"
          accept=".json"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleLoad}
        />
        <Button
          type="default"
          onClick={() => fileInputRef.current.click()}
          style={{ width: '100%', marginBottom: '10px' }}
        >
          Load
        </Button></>)}
      </div>

      {/* Entry */}
      <div style={categoryStyle} onClick={() => toggleCategory(setEntryOpen)}>
        Entry {isEntryOpen ? '▲' : '▼'}
      </div>
      {isEntryOpen && (
        <div style={{ marginLeft: '10px' }}>
          <div
            className="dndnode input"
            onDragStart={(event) => onDragStart(event, 'promptEntry')}
            draggable
            style={nodeStyle}
          >
            <BsPencil style={{ marginRight: '8px' }} /> Prompt Entry
          </div>
          <div
            className="dndnode"
            onDragStart={(event) => onDragStart(event, 'imageNode')}
            draggable
            style={nodeStyle}
          >
            <BsImage style={{ marginRight: '8px' }} /> Image Node
          </div>
        </div>
      )}
      {/* Notes Section */}
      <div style={categoryStyle} onClick={toggleNotes}>
        Notes {isNotesOpen ? '▲' : '▼'}
      </div>
      {isNotesOpen && (
        <div style={{ marginLeft: '10px' }}>
          <div
            className="dndnode"
            onDragStart={(event) => onDragStart(event, 'shapeTextNode')}
            draggable
            style={nodeStyle}
          >
            <BsSticky style={{ marginRight: '8px' }} /> Text Node
          </div>
          <div
  className="dndnode"
  onDragStart={(event) => onDragStart(event, 'shapeNode')}
  draggable
  style={nodeStyle}
>
  <BsFillSquareFill style={{ marginRight: '8px' }} /> Shape
</div>
        </div>
        
      )}
     
      {/* Node Functions */}
      <div style={categoryStyle} onClick={() => toggleCategory(setNodeFunctionsOpen)}>
        Node Functions {isNodeFunctionsOpen ? '▲' : '▼'}
      </div>
      {isNodeFunctionsOpen && (
        <div style={{ marginLeft: '10px' }}>
          <div
            className="dndnode"
            onDragStart={(event) => onDragStart(event, 'splitNode')}
            draggable
            style={nodeStyle}
          >
            <BsList style={{ marginRight: '8px' }} /> Split Node
          </div>
          <div
            className="dndnode"
            onDragStart={(event) => onDragStart(event, 'combineNode')}
            draggable
            style={nodeStyle}
          >
            <BsGraphUp style={{ marginRight: '8px' }} /> Combine Node
          </div>
          <div
            className="dndnode"
            onDragStart={(event) => onDragStart(event, 'decisionNode')}
            draggable
            style={nodeStyle}
          >
            <BsQuestionDiamondFill style={{ marginRight: '8px' }} /> Decision Node
          </div>
          <div
            className="dndnode"
            onDragStart={(event) => onDragStart(event, 'regexNode')}
            draggable
            style={nodeStyle}
            title="Regex Node"
          >
            <BsTextLeft style={{ marginRight: '8px' }} /> Regex Node
          </div>
        </div>
      )}

   {/* Logging */}
   <div style={categoryStyle} onClick={toggleLogging}>
        Logging {isLoggingOpen ? '▲' : '▼'}
      </div>
      {isLoggingOpen && (
        <div style={{ marginLeft: '10px' }}>
          <div
            className="dndnode"
            onDragStart={(event) => onDragStart(event, 'log')}
            draggable
            style={nodeStyle}
          >
            <BsList style={{ marginRight: '8px' }} /> Log
          </div>
        </div>
      )}
 {/* Custom Nodes */}
      <div style={{ marginBottom: '25px' }}>
        {/* Render custom nodes grouped by category */}
        {Object.keys(customNodesByCategory).map((category) => (
          <div key={category}>
            <div
              style={categoryStyle}
              onClick={() => toggleCustomCategory(category)}
            >
              {category} {customCategoriesOpen[category] ? '▲' : '▼'}
            </div>
            {customCategoriesOpen[category] && (
              <div style={{ marginLeft: '10px' }}>
                {customNodesByCategory[category].map((node) => {
                  const IconComponent = node.iconName ? Icons[node.iconName] : null;

                  return (
                    <div
                      key={node.type}
                      className="dndnode"
                      style={{
                        marginBottom: '10px',
                        padding: '10px',
                        background: '#fff',
                        border: '1px solid #ddd',
                        cursor: 'grab',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        onDragStart={(event) => onDragStart(event, node.type)}
                        draggable
                        style={{ flex: 1, display: 'flex', alignItems: 'center' }}
                      >
                        {/* Display the icon */}
                        {IconComponent && (
                          <IconComponent style={{ marginRight: '8px' }} />
                        )}
                        {node.label}
                      </div>
                      <div style={{ display: 'flex', gap: '5px' }}>
                        <Tooltip title="Edit">
                          <Button
                            icon={<EditOutlined />}
                            size="small"
                            onClick={() => editCustomNodeType(node.type)}
                          />
                        </Tooltip>
                        <Tooltip title="Delete">
  <Button
    icon={<DeleteOutlined />}
    size="small"
    onClick={async () => await deleteCustomNodeType(node.type)}
  />
</Tooltip>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        ))}
      </div>
      {userAccess === 'advanced' && (
      <>
      <Button
        type="default"
        onClick={showCustomApiModal}
        style={{ width: '100%', marginBottom: '10px' }}
      >
        New Node
      </Button>
</>)}
    
      <Button
  type="primary"
  style={{ width: '100%', marginTop: '10px' }}
  onClick={showMarketplace}
>
  Marketplace
</Button>

   
    </aside>
  );
};

// Define styles outside the component for better performance
const categoryStyle = {
  backgroundColor: 'black',
  color: 'white',
  padding: '10px',
  fontWeight: 'bold',
  cursor: 'pointer',
  borderRadius: '8px',
  marginBottom: '10px',
};

const nodeStyle = {
  marginBottom: '10px',
  padding: '10px',
  background: '#fff',
  border: '1px solid #ddd',
  cursor: 'grab',
  display: 'flex',
  alignItems: 'center',
};

export default Sidebar;
