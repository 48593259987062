// src/utils/helpers.js

export const getNodeById = (nodes, nodeId) => nodes.find((node) => node.id === nodeId);

export const getConnectedNodes = (edges, nodeId) => {
  const connectedEdges = edges.filter((edge) => edge.source === nodeId);
  return connectedEdges.map((edge) => edge.target);
};

export const getIncomingEdges = (edges, nodeId) => {
  return edges.filter((edge) => edge.target === nodeId);
};
