import React from 'react';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import './LoggedOut.css';
import MovingDotsBackground from './MovingDotsBackground'; // Import the moving dots background component

const LoggedOut = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <div className="logged-out-wrapper">
      {/* Background component */}
      <MovingDotsBackground /> 

      <Container className="main-content">
             {/* Second hero image underneath the button */}
             <Row className="justify-content-center mt-4">
          <Col md={8} className="text-center">
            <Image
              src="hero.png" // Replace with the path to your second hero image
              alt="Hero"
              fluid
              className="hero"
            />
          </Col>
        </Row>
        {/* Main heading */}
        <Row className="justify-content-center">
          <Col md={8} className="text-center">
            <h1>Node-Based <br/>AI Workflow Builder</h1>
            <p>Build, automate, and scale workflows visually with customizable nodes and seamless API integration.</p>
          </Col>
        </Row>

        {/* Paragraph text followed by Login button */}
        <Row className="justify-content-center mt-4">
          <Col md={8} className="text-center">
            <Button variant="primary" onClick={() => loginWithRedirect()} className="mt-3 btn-login">
              Log In
            </Button>
          </Col>
        </Row>

   
      </Container>
    </div>
  );
};

export default LoggedOut;