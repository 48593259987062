// src/components/nodes/SplitNode.js

import React from 'react';
import { Handle } from 'react-flow-renderer';

const SplitNode = ({ data }) => {
  return (
    <div style={{ padding: '10px', border: '1px solid black', borderRadius: '4px', backgroundColor: '#ffe0b2' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>Split Node</span>
        <span
          style={{ background: 'black', color: 'white', width: '16px', height: '16px', textAlign: 'center', cursor: 'pointer' }}
          onClick={data.deleteNode}
        >
          x
        </span>
      </div>
      <Handle type="target" position="left" id="input" />
      {/* Allow multiple outputs */}
      <Handle type="source" position="right" id="output1" style={{ top: '30%' }} />
      <Handle type="source" position="right" id="output2" style={{ top: '70%' }} />
    </div>
  );
};

export default SplitNode;
