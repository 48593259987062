import React, { useState } from 'react';
import { useReactFlow } from 'react-flow-renderer';

import './NodeStyling.css'; // Your custom node styling

const ShapeTextNode = ({ data, id }) => {
    const { setNodes } = useReactFlow();
    // eslint-disable-next-line
  const [selectedShape, setSelectedShape] = useState('rectangle'); // Default shape
  // eslint-disable-next-line
  const [nodeText, setNodeText] = useState(data.text || '');
  
  // Handle text changes
  const handleTextChange = (event) => {
    const newText = event.target.value;

    // Update node's data using setNodes
    setNodes((nds) =>
      nds.map((node) =>
        node.id === id
          ? {
              ...node,
              data: {
                ...node.data,
                nodeText: newText,
              },
            }
          : node
      )
    );
  };

  return (
    <div className={` ${selectedShape}`} style={{ borderColor:'#000', borderStyle:'solid', borderWidth:1, borderRadius:6,paddingLeft:10,paddingRight:10,paddingBottom:8 }}>
      {/* Delete Button - Always shows */}
      <span className="node-delete" style={{ position: 'absolute', top: '-10px', right: '-10px' }} onClick={data.deleteNode}>x</span>

      {/* Text Input Field */}
      <textarea
        value={data.nodeText || ''}
        onChange={handleTextChange}
        style={{ width: '100%', marginTop: '10px', height: '60px', borderRadius: '4px', backgroundColor: 'transparent', border: '1px solid #ddd' }}
        placeholder="Enter your text here..."
      />
    </div>
  );
};

export default ShapeTextNode;