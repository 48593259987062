import React, { useEffect, useRef } from 'react';

const DynamicBackground = () => {
  const canvasRef = useRef(null);
  const baseRadius = 2; // Base radius of the dots
  const maxRadius = 2.6; // Maximum radius of the dots when fully expanded
  const gap = 30; // Gap between dots
  const shiftSpeed = 0.01; // Speed of the grid shifting in one direction

  // State for managing dot state (on/off)
  const initializeDotsState = (rows, cols) => {
    const state = [];
    for (let i = 0; i < rows * cols; i++) {
      state.push(Math.random() < 0.4); // 90% chance for the dot to start "on"
    }
    return state;
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    let shiftX = 0;
    let shiftY = 0;
    const toggleInterval = 400; // Time between toggling dots on/off
    let dotState = []; // To hold whether dots are on or off

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight - 50; // Adjust for navbar
      const rows = Math.ceil(canvas.height / gap);
      const cols = Math.ceil(canvas.width / gap);
      dotState = initializeDotsState(rows, cols); // Initialize dot state
      drawDots(); // Redraw dots on resize
    };

    // Draw the dots with the effect of growing and shrinking
    const drawDots = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas
      const rows = Math.ceil(canvas.height / gap); // Calculate number of rows based on height
      const cols = Math.ceil(canvas.width / gap); // Calculate number of columns based on width

      for (let row = 0; row < rows; row++) {
        for (let col = 0; col < cols; col++) {
          const index = row * cols + col;
          if (!dotState[index]) continue; // Skip "off" dots

          // Calculate position with shifting
          const x = col * gap + gap / 2 + shiftX;
          const y = row * gap + gap / 2 + shiftY;

          // Slow growing and shrinking effect
          const currentRadius = baseRadius + (Math.sin(Date.now() / 10000 + (row + col)) * (maxRadius - baseRadius));

          ctx.beginPath();
          ctx.arc(x, y, currentRadius, 0, Math.PI * 2);
          ctx.fillStyle = 'rgba(255, 255, 255, 0.5)'; // Color of the dots
          ctx.fill();
        }
      }
    };

    // Handle the slight shifting of the entire grid in one direction
    const shiftDots = () => {
      shiftX += shiftSpeed;
      shiftY += shiftSpeed;

      // Loop back to start to maintain grid within screen bounds
      if (shiftX >= gap) shiftX = 0;
      if (shiftY >= gap) shiftY = 0;
    };

    // Randomly turn dots on and off
    const toggleDots = () => {
      for (let i = 0; i < dotState.length; i++) {
        if (Math.random() < 0.01) { // 5% chance to toggle each dot
          dotState[i] = !dotState[i];
        }
      }
    };

    const animate = () => {
      drawDots(); // Draw the dots with the growing and shrinking effect
      shiftDots(); // Shift the dots slightly in one direction
      requestAnimationFrame(animate); // Continue the animation
    };

    // Handle resizing
    window.addEventListener('resize', resizeCanvas);
    resizeCanvas(); // Initial canvas sizing

    animate(); // Start the animation loop

    // Toggle dots on and off at intervals
    const toggleIntervalId = setInterval(toggleDots, toggleInterval);

    return () => {
      window.removeEventListener('resize', resizeCanvas); // Cleanup listener
      clearInterval(toggleIntervalId); // Cleanup interval
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      className="canvas-background"
      style={{
        backgroundColor: 'black',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        zIndex: -1,
      }}
    />
  );
};

export default DynamicBackground;