import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, Button, Spinner, Alert, Modal, Form, Tooltip } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import './LoggedOut.css';
import { BsPlusCircle, BsTrash, BsPencilSquare, BsArrowRightCircle } from 'react-icons/bs';
import DynamicBackground from './DynamicBackground';

const LoggedIn = () => {
  const navigate = useNavigate();
  const { user, isAuthenticated } = useAuth0();
  const { loading, error } = useContext(AuthContext);

  // State to store userId and userAccess based on email from Auth0
  const [userId, setUserId] = useState(localStorage.getItem('userId') || '');
  // eslint-disable-next-line
  const [userAccess, setUserAccess] = useState(localStorage.getItem('userAccess') || '');
  const [boards, setBoards] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [boardName, setBoardName] = useState('');
  const [boardDescription, setBoardDescription] = useState('');
  const [showEditModal, setShowEditModal] = useState(false);
  const [editBoardId, setEditBoardId] = useState(null);
  const [editBoardName, setEditBoardName] = useState('');
  const [editBoardDescription, setEditBoardDescription] = useState('');

  useEffect(() => {
    if (isAuthenticated && user) {
      const email = user.email;
      if (email) {
        fetchUserIdAndAccess(email);
      }
    }
    // eslint-disable-next-line
  }, [isAuthenticated, user]);

  const fetchUserIdAndAccess = async (email) => {
    try {
      const response = await axios.post(
        'https://nodecodestudio.com/backend/login.php',
        { email: email },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      const { user_id, user_access } = response.data;
      setUserId(user_id);
      setUserAccess(user_access);
      localStorage.setItem('userId', user_id);
      localStorage.setItem('userAccess', user_access);
      fetchUserBoards(user_id);
    } catch (err) {
      console.error('Error fetching user ID and access level:', err);
    }
  };

  const fetchUserBoards = async (userId) => {
    try {
      const response = await axios.get(
        `https://nodecodestudio.com/backend/get_boards.php?user_id=${userId}`
      );
      setBoards(response.data.boards);
    } catch (err) {
      console.error('Error fetching boards:', err);
    }
  };

  const handleCreateBoard = async () => {
    if (!boardName.trim()) {
      alert('Board name cannot be empty.');
      return;
    }
    try {
      const response = await axios.post(
        'https://nodecodestudio.com/backend/create_board.php',
        { user_id: userId, board_name: boardName, board_description: boardDescription },
        { headers: { 'Content-Type': 'application/json' } }
      );
      const newBoardId = response.data.board_id;
      setShowCreateModal(false);
      fetchUserBoards(userId);
      navigate(`/node-creator?id=${newBoardId}`);
    } catch (err) {
      console.error('Error creating board:', err);
      alert('Failed to create board. Please try again.');
    }
  };

  const handleDeleteBoard = async (boardId) => {
    if (!window.confirm('Are you sure you want to delete this board? This action cannot be undone.')) {
      return;
    }
    try {
      await axios.post('https://nodecodestudio.com/backend/delete_board.php', { user_id: userId, board_id: boardId });
      fetchUserBoards(userId);
    } catch (err) {
      console.error('Error deleting board:', err);
      alert('Failed to delete board. Please try again.');
    }
  };

  const handleEditBoard = (boardId) => {
    const boardToEdit = boards.find(board => board.board_id === boardId);
    if (boardToEdit) {
      setEditBoardId(boardId);
      setEditBoardName(boardToEdit.board_name);
      setEditBoardDescription(boardToEdit.board_description);
      setShowEditModal(true);
    }
  };

  const handleSaveEditBoard = async () => {
    if (!editBoardName.trim()) {
      alert('Board name cannot be empty.');
      return;
    }
    try {
      await axios.post('https://nodecodestudio.com/backend/update_board.php', {
        user_id: userId,
        board_id: editBoardId,
        board_name: editBoardName,
        board_description: editBoardDescription,
      });
      setShowEditModal(false);
      fetchUserBoards(userId);
    } catch (err) {
      console.error('Error updating board:', err);
      alert('Failed to update board. Please try again.');
    }
  };

  if (loading) {
    return (
      <Container className="main-content">
        <Row className="justify-content-center">
          <Col md={8} className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="main-content">
        <Row className="justify-content-center">
          <Col md={8}>
            <Alert variant="danger">{error}</Alert>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <>
      <DynamicBackground />
      <Modal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        dialogClassName="edit-board-modal"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Board</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formEditBoardName">
              <Form.Label>Board Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter new board name"
                value={editBoardName}
                onChange={(e) => setEditBoardName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formEditBoardDescription">
              <Form.Label>Board Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter new board description"
                value={editBoardDescription}
                onChange={(e) => setEditBoardDescription(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setShowEditModal(false)}>
            Close
          </Button>
          <Button variant="outline-primary" onClick={handleSaveEditBoard}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="logged-in-wrapper">
        <Container className="main-content">
          <Row className="justify-content-center">
            <Col md={10} className="text-center">
              <h1 className="page-title">STUDIO Builder</h1>
             
              <Button
                variant="dark"
                onClick={() => setShowCreateModal(true)}
                className="mb-5 create-board-btn"
              >
               <span><BsPlusCircle /> </span> <span>Create New Board</span>
              </Button>
              <h2 className="mb-4">Your Boards</h2>
              {boards.length === 0 ? (
                <p className="no-boards">You have no boards. Click the button above to create your first board!</p>
              ) : (
                <Row className="board-list">
                  {boards.map(board => (
                    <Col md={6} lg={4} key={board.board_id}>
                      <div className="board-card">
                        <h5 className="board-title">{board.board_name}</h5>
                        <p className="board-description">{board.board_description}</p>
                        <div className="board-actions">
                          <Tooltip title="Open Board">
                            <Link to={`/node-creator?id=${board.board_id}`} className="btn">
                              <BsArrowRightCircle />
                            </Link>
                          </Tooltip>
                          <Tooltip title="Edit Board">
                            <Button variant="outline-info" onClick={() => handleEditBoard(board.board_id)}>
                              <BsPencilSquare />
                            </Button>
                          </Tooltip>
                          <Tooltip title="Delete Board">
                            <Button
                              variant="outline-danger"
                              onClick={() => handleDeleteBoard(board.board_id)}
                            >
                              <BsTrash />
                            </Button>
                          </Tooltip>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              )}
            </Col>
          </Row>
        </Container>
      </div>

      <Modal show={showCreateModal} onHide={() => setShowCreateModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Create New Board</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formBoardName">
              <Form.Label>Board Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter board name"
                value={boardName}
                onChange={(e) => setBoardName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formBoardDescription">
              <Form.Label>Board Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter board description"
                value={boardDescription}
                onChange={(e) => setBoardDescription(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setShowCreateModal(false)}>
            Close
          </Button>
          <Button variant="outline-primary" onClick={handleCreateBoard}>
            Create Board
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LoggedIn;