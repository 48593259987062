import React, { useState, useEffect } from 'react';
import { Handle } from 'react-flow-renderer';
import { Upload, Button, Input, message, Modal } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { BsImage } from 'react-icons/bs';
import './NodeStyling.css';

const ImageNode = ({ data, id }) => {
  const { setNodes, imageData: initialImageData } = data;
  const [imageData, setImageData] = useState(initialImageData || '');
  const [imageUrl, setImageUrl] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(true);

  useEffect(() => {
    // Show the modal when the node is initialized if no image data is present
    if (!imageData) {
      
      setIsModalVisible(true);
    }
    // eslint-disable-next-line
  }, []);

  const handleImageUpload = (info) => {
    console.log('Upload Info:', info);

    // Access the file directly from info.file
    const file = info.file.originFileObj || info.file;

    // Validate that the file exists and is of type Blob or File
    if (!file) {
      message.error('No file selected or file is invalid.');
      return;
    }

    if (!(file instanceof Blob)) {
      message.error('Selected file is not a valid image.');
      return;
    }

    const reader = new FileReader();

    reader.onload = () => {
      const base64Data = reader.result;
      setImageData(base64Data);
      setIsModalVisible(false);

      // Update the node's data
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === id) {
            return {
              ...node,
              data: {
                ...node.data,
                imageData: base64Data,
              },
            };
          }
          return node;
        })
      );
    };

    reader.onerror = () => {
      message.error('Error reading the file.');
    };

    // Read the file as Data URL
    reader.readAsDataURL(file);
  };

  const handleImageUrlChange = (e) => {
    const url = e.target.value;
    setImageUrl(url);
    setImageData(url);
    setIsModalVisible(false);

    // Update the node's data
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: {
              ...node.data,
              imageData: url,
            },
          };
        }
        return node;
      })
    );
  };

  const handleModalCancel = () => {
    // You can either leave the modal open or close it if no image is selected
    setIsModalVisible(false);
  };

  return (
    <div className="node-border">
      {/* Node Title */}
      <div className='topBar'>
        <BsImage className="nodeIcon" /> <span className='node-title'>
        Image Node</span>
        <span
          className="node-delete"
          onClick={data.deleteNode}
        >
          x
        </span>
      </div>

      {/* Display the Image */}
      {imageData && (
        <div style={{ marginTop: '10px' }}>
          <img
            src={imageData}
            alt="Uploaded"
            style={{ maxWidth: '100%', maxHeight: '200px', marginTop: 20 }}
          />
        </div>
      )}

      {/* Modal to prompt for image upload or URL */}
      <Modal
        title="Upload Image or Enter URL"
        open={isModalVisible}
        onCancel={handleModalCancel}
        footer={[
          <Button key="cancel" onClick={handleModalCancel}>
            Cancel
          </Button>,
        ]}
      >
        <Upload
          accept="image/*"
          showUploadList={false}
          beforeUpload={() => false} // Prevent automatic upload
          onChange={handleImageUpload}
          multiple={false} // Ensure single file upload
        >
          <Button icon={<UploadOutlined />}>Upload Image</Button>
        </Upload>

        <Input
          placeholder="Enter Image URL"
          value={imageUrl}
          onChange={handleImageUrlChange}
          style={{ marginTop: '10px' }}
        />
      </Modal>

      {/* Output Handle */}
      <Handle
        type="source"
        position="right"
        id="output"
        style={{ top: '50%', transform: 'translateY(-50%)', position: 'absolute' }}
      />
    </div>
  );
};

ImageNode.defaultData = {
  imageData: '',
};

export default ImageNode;