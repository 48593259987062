import React from 'react';
import { Handle } from 'react-flow-renderer';
import { DownloadOutlined } from '@ant-design/icons';
import { BsList } from 'react-icons/bs';
import './NodeStyling.css';
const LogNode = ({ data, id }) => {
  // eslint-disable-next-line
  const { logContent, deleteNode } = data;

  const isImage = (content) => {
    return (
      typeof content === 'string' &&
      content.startsWith('data:image/') // Check if content is a Data URL
    );
  };

  const handleDownload = () => {
    if (isImage(logContent)) {
      // For images
      const link = document.createElement('a');
      link.href = logContent;
      link.download = 'image.png'; // You may want to set a dynamic file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else if (typeof logContent === 'string' || typeof logContent === 'object') {
      // For text or objects
      const contentToDownload =
        typeof logContent === 'object'
          ? JSON.stringify(logContent, null, 2)
          : logContent;

      const blob = new Blob([contentToDownload], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = 'log.txt'; // You may want to set a dynamic file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Release the object URL after download
      URL.revokeObjectURL(url);
    } else {
      // Handle other data types if necessary
      alert('Cannot download this type of content.');
    }
  };

  return (
    <div
   className="node-border"
      style={{
      
        maxWidth: '250px',
      }}
    >
   

   <div className='topBar'>
        <BsList className="nodeIcon" /> <span className='node-title'>
        Log Node</span>
        <span
          className="node-delete"
          onClick={data.deleteNode}
        >
          x
        </span>
      </div>
      {/* Display the Logged Content */}
      {logContent && (
        <div style={{ marginTop: '10px', whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
          {isImage(logContent) ? (
            <img
              src={logContent}
              alt="Logged"
              style={{ maxWidth: '100%', maxHeight: '100px' }}
            />
          ) : typeof logContent === 'object' ? (
            <pre>{JSON.stringify(logContent, null, 2)}</pre>
          ) : (
            <div>{logContent}</div>
          )}
        </div>
      )}

      {/* Download Button */}
      {logContent && (
        <div style={{ position: 'absolute', bottom: '5px', right: '5px' }}>
          <DownloadOutlined
            style={{ fontSize: '16px', cursor: 'pointer' }}
            onClick={handleDownload}
          />
        </div>
      )}

      {/* Input and Output Handles */}
      <Handle
        type="target"
        position="left"
        id="input"
        style={{ top: '50%', transform: 'translateY(-50%)' }}
      />
      <Handle
        type="source"
        position="right"
        id="output"
        style={{ top: '50%', transform: 'translateY(-50%)' }}
      />
    </div>
  );
};

LogNode.defaultData = {
  logContent: '',
  deleteNode: () => {},
};

export default LogNode;
